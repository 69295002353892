import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import ResultsText from "../results-text/ResultsText";
import "./IntroShareholderReturn.scss";
import ReportingSubHeading from "../components/ReportingSubHeading";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

export const pricingStrategyMaxClicks = 7;
const IntroShareholderReturn = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < pricingStrategyMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);
  const showCube = context.noAnimations || context.clicks >= 1;
  const showDividendHeader = context.noAnimations || context.clicks >= 2;
  const showDividendInfo = context.noAnimations || context.clicks >= 3;
  const showButBackHeader = context.noAnimations || context.clicks >= 4;
  const showButBackInfo = context.noAnimations || context.clicks >= 5;
  const showSharePriceHeader = context.noAnimations || context.clicks >= 6;
  const showSharePriceInfo = context.noAnimations || context.clicks >= 7;
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="What drives shareholder return?" />
          <ReportingSubHeading subHeading="Growing profitably but sustainably will provide a strong dividend stream into the future plus a share price premium" />
          <div className="share-holder-return-slide">
            <div
              className={classNames("cube-container", {
                show: showCube,
              })}
            >
              <InlineGroup center>
                <Icon
                  type="cube"
                  dynamicSize={{
                    min: "200px",
                    preferred: "22vw",
                    max: "1000px",
                  }}
                />
              </InlineGroup>
            </div>
            <InlineGroup center verticalCenter className="arrows-container">
              <InlineGroup
                center
                verticalCenter
                className="top-arrow-container"
              >
                <InlineGroup
                  className={classNames("buybacks-header", {
                    show: showButBackHeader,
                  })}
                  center
                >
                  <Icon
                    type="topArrow"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "900px",
                    }}
                  />
                </InlineGroup>
                <InlineGroup block className="buybacks-info-container">
                  <Icon
                    className={classNames("buybacks-header", {
                      show: showButBackHeader,
                    })}
                    type="buybacks"
                    dynamicSize={{
                      min: "50px",
                      preferred: "6vw",
                      max: "900px",
                    }}
                  />
                  <VerticalGroup className="buyback-group" wide>
                    <ResultsText
                      className={classNames("buybacks-heading", {
                        show: showButBackHeader,
                      })}
                      bold
                    >
                      Share Issues / Buybacks
                    </ResultsText>
                    <ul
                      className={classNames("buybacks-return-points", {
                        show: showButBackInfo,
                      })}
                    >
                      <li className="mb-2">
                        <ResultsText left>
                          When asking shareholders for more money, make sure you
                          earn a sufficient return for them.
                        </ResultsText>
                      </li>
                      <li>
                        <ResultsText left>
                          Returning capital (buybacks) is a good option when you
                          have excess capital.
                        </ResultsText>
                      </li>
                    </ul>
                  </VerticalGroup>
                </InlineGroup>
              </InlineGroup>
              <VerticalGroup
                center
                verticalCenter
                className="bottom-arrow-container"
              >
                <Icon
                  className={classNames("share-price-header", {
                    show: showSharePriceHeader,
                  })}
                  type="bottomArrow"
                  dynamicSize={{ min: "50px", preferred: "5vw", max: "900px" }}
                />

                <InlineGroup block className="share-info-container">
                  <Icon
                    className={classNames("share-price-header", {
                      show: showSharePriceHeader,
                    })}
                    type="sharePrice"
                    dynamicSize={{
                      min: "50px",
                      preferred: "6vw",
                      max: "900px",
                    }}
                  />
                  <VerticalGroup className="share-price-group" wide>
                    <ResultsText
                      className={classNames("share-price-header", {
                        show: showSharePriceHeader,
                      })}
                      bold
                    >
                      Share Price
                    </ResultsText>
                    <ul
                      className={classNames(
                        "share-price-header share-holder-return-points",
                        {
                          show: showSharePriceInfo,
                        },
                      )}
                    >
                      <li className="mb-2">
                        <ResultsText left>
                          Share price reflects future dividends, share issues /
                          buybacks and a terminal value.
                        </ResultsText>
                      </li>
                      <li>
                        <ResultsText left>
                          The terminal value considers the size of your book and
                          the stability of your returns.
                        </ResultsText>
                      </li>
                    </ul>
                  </VerticalGroup>
                </InlineGroup>
              </VerticalGroup>
              <InlineGroup
                center
                verticalCenter
                className="left-arrow-container"
              >
                <Icon
                  className={classNames("dividends-header", {
                    show: showDividendHeader,
                  })}
                  type="leftArrow"
                  dynamicSize={{ min: "70px", preferred: "8vw", max: "900px" }}
                />

                <div className="dividends-info-container">
                  <VerticalGroup wide>
                    <InlineGroup
                      className={classNames("dividends-header", {
                        show: showDividendHeader,
                      })}
                      spread
                      block
                      verticalCenter
                    >
                      <ResultsText bold>Dividends Stream</ResultsText>
                      <Icon
                        type="dividend"
                        dynamicSize={{
                          min: "50px",
                          preferred: "6vw",
                          max: "900px",
                        }}
                      />
                    </InlineGroup>
                    <VerticalGroup
                      className={classNames("dividends-info", {
                        show: showDividendInfo,
                      })}
                    >
                      <ul className="dividends-return-points">
                        <li className="mb-2">
                          <ResultsText left>
                            Focus on building a sustainable dividends stream.
                          </ResultsText>
                        </li>
                        <li>
                          <ResultsText left>
                            Dividends are 75% of NPAT.
                          </ResultsText>
                        </li>
                      </ul>
                    </VerticalGroup>
                  </VerticalGroup>
                </div>
              </InlineGroup>
            </InlineGroup>
          </div>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroShareholderReturn;
