import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

import { useResultsContext } from "../context/context";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import ResultsInfoCard from "../components/ResultsInfoCard";
import classNames from "classnames";
import { NumberBadge } from "./IntroGetToKnowYourBank";
import "./IntroDecisionTime.scss";

export const decisionTimeMaxClicks = 3;
interface Props {
  projectsEnabled: boolean;
  capitalManagement: API.CapitalManagement;
  round1Config: ModelAPI.Reporting.ReportingRound;
}

const IntroDecisionTime = ({
  projectsEnabled,
  capitalManagement,
  round1Config,
}: Props) => {
  const { durationInMinutes, dealRoomEnabled, excoEnabled } = round1Config;

  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < decisionTimeMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showDecisionsToMake = context.noAnimations || context.clicks >= 1;
  const hideInfoPoints = context.clicks >= 2;
  const animateBadge = context.noAnimations || context.clicks >= 3;

  const orderedDecisions = [
    excoEnabled
      ? "Executive Decisions: Read scenario, Review the options and decide"
      : null,
    projectsEnabled
      ? "Special Projects: Decide which Special Project to invest in."
      : null,
    "Business Screen: Decide how you will manage your products",
    capitalManagement === "full_capital_management"
      ? "Treasury: Decide how you raise sufficient capital to fund your growth"
      : capitalManagement === "set_credit_rating"
        ? "Treasury: Decide if you want to change your credit rating"
        : null,
    dealRoomEnabled ? "Deal Room: Review and consider" : null,
  ].filter(Boolean);

  const infoPoints = hideInfoPoints ? [] : ["Complete Your Round 1 Decisions"];

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="decision-time-slide" wide fullHeight>
        <VerticalGroup wide full spaceBetweenElements={4}>
          <InlineGroup block center>
            <EditableReportingHeading defaultHeading="Round 1 Decision Time" />
          </InlineGroup>
          <InlineGroup center width="55%">
            <ResultsText bold size="lg" colour="primaryDark">
              {durationInMinutes - 20} minutes for Round 1 decisions
            </ResultsText>
          </InlineGroup>
          <VerticalGroup wide full spread>
            <InlineGroup>
              <InlineGroup>
                <ResultsInfoCard
                  animateBadge={animateBadge}
                  themeColour="blue"
                  BadgeText={`${durationInMinutes - 20}m`}
                  headerText="Round 1 Decisions"
                  infoPoints={infoPoints}
                  initialTimeInMinutes={durationInMinutes - 20}
                />
              </InlineGroup>
              <VerticalGroup
                className={classNames("round-one-decisions pt-5", {
                  show: showDecisionsToMake,
                })}
                style={{ height: "95%" }}
                spaceBetweenElements={6}
              >
                {orderedDecisions.map((decision, index) => (
                  <NumberBadge
                    badgeColour="blue"
                    key={index}
                    number={index + 1}
                    text={decision}
                  />
                ))}
              </VerticalGroup>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroDecisionTime;
