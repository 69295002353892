import React, { ReactElement } from "react";
import classNames from "classnames";
import Tooltip from "../tooltip/Tooltip";

import { ReactComponent as homeIcon } from "../../../assets/header/home.svg";

import { ReactComponent as assessmentsIcon } from "../../../assets/sidebar/assessments.svg";
import { ReactComponent as brandingIcon } from "../../../assets/sidebar/branding.svg";
import { ReactComponent as emailIcon } from "../../../assets/sidebar/email.svg";
import { ReactComponent as eventsIcon } from "../../../assets/sidebar/events.svg";
import { ReactComponent as settingsIcon } from "../../../assets/sidebar/settings.svg";
import { ReactComponent as simulationsIcon } from "../../../assets/sidebar/simulations.svg";
import { ReactComponent as scheduleIcon } from "../../../assets/sidebar/schedule.svg";
import { ReactComponent as filterIcon } from "../../../assets/sidebar/filter.svg";
import { ReactComponent as starIcon } from "../../../assets/sidebar/star.svg";
import { ReactComponent as usersIcon } from "../../../assets/sidebar/users.svg";
import { ReactComponent as clientsIcon } from "../../../assets/sidebar/clients.svg";
import { ReactComponent as diskIcon } from "../../../assets/sidebar/disk.svg";
import { ReactComponent as gameControllerIcon } from "../../../assets/sidebar/game.svg";
import { ReactComponent as auditLogIcon } from "../../../assets/sidebar/audit.svg";
import { ReactComponent as tabletConfig } from "../../../assets/sidebar/tabletConfig.svg";

import { ReactComponent as pencilIcon } from "../../../assets/common/pencil.svg";
import { ReactComponent as banIcon } from "../../../assets/common/ban.svg";
import { ReactComponent as trashIcon } from "../../../assets/common/trash.svg";
import { ReactComponent as copyIcon } from "../../../assets/common/copy.svg";
import { ReactComponent as clipboard } from "../../../assets/common/clipboard.svg";
import { ReactComponent as cloneIcon } from "../../../assets/common/clone.svg";
import { ReactComponent as closeIcon } from "../../../assets/common/close.svg";
import { ReactComponent as economic } from "../../../assets/common/economic.svg";
import { ReactComponent as editIcon } from "../../../assets/common/edit.svg";
import { ReactComponent as keyIcon } from "../../../assets/common/key.svg";
import { ReactComponent as tickIcon } from "../../../assets/common/tick.svg";
import { ReactComponent as lockIcon } from "../../../assets/common/lock.svg";
import { ReactComponent as maximize } from "../../../assets/common/maximize.svg";
import { ReactComponent as lockOpenIcon } from "../../../assets/common/lockOpen.svg";
import { ReactComponent as lockClosedIcon } from "../../../assets/common/lockClosed.svg";
import { ReactComponent as unlockIcon } from "../../../assets/common/unlocked.svg";
import { ReactComponent as ideaIcon } from "../../../assets/common/idea.svg";
import { ReactComponent as mailIcon } from "../../../assets/common/mail.svg";
import { ReactComponent as uploadIcon } from "../../../assets/common/upload.svg";
import { ReactComponent as searchIcon } from "../../../assets/common/search.svg";
import { ReactComponent as addUserIcon } from "../../../assets/common/addUser.svg";
import { ReactComponent as addEventIcon } from "../../../assets/common/addEvent.svg";
import { ReactComponent as exportIcon } from "../../../assets/common/export.svg";
import { ReactComponent as importIcon } from "../../../assets/common/import.svg";
import { ReactComponent as plusIcon } from "../../../assets/common/plus.svg";
import { ReactComponent as arrowIcon } from "../../../assets/common/arrow.svg";
import { ReactComponent as infoIcon } from "../../../assets/common/info.svg";
import { ReactComponent as draggableIcon } from "../../../assets/common/draggable.svg";
import { ReactComponent as group } from "../../../assets/common/group.svg";
import { ReactComponent as preview } from "../../../assets/common/preview.svg";
import { ReactComponent as refresh } from "../../../assets/common/refresh.svg";
import { ReactComponent as calculator } from "../../../assets/common/calculator.svg";
import { ReactComponent as help } from "../../../assets/common/help.svg";
import { ReactComponent as sculpting } from "../../../assets/common/sculpting.svg";
import { ReactComponent as treasure } from "../../../assets/common/treasure.svg";
import { ReactComponent as eye } from "../../../assets/common/eye.svg";
import { ReactComponent as hidden } from "../../../assets/common/hidden.svg";
import { ReactComponent as more } from "../../../assets/common/more.svg";
import { ReactComponent as compare } from "../../../assets/common/compare.svg";
import { ReactComponent as slider } from "../../../assets/common/slider.svg";
import { ReactComponent as publish } from "../../../assets/common/publish.svg";
import { ReactComponent as error } from "../../../assets/common/error.svg";
import { ReactComponent as leftChevron } from "../../../assets/common/chevronLeft.svg";
import { ReactComponent as rightChevron } from "../../../assets/common/chevronRight.svg";
import { ReactComponent as ExcelFile } from "../../../assets/common/excelFile.svg";
import { ReactComponent as TextSize } from "../../../assets/common/textSize.svg";
import { ReactComponent as cast } from "../../../assets/common/cast.svg";
import { ReactComponent as font } from "../../../assets/common/font.svg";
import { ReactComponent as presenter } from "../../../assets/common/presenter.svg";
import { ReactComponent as row } from "../../../assets/common/row.svg";
import { ReactComponent as image } from "../../../assets/common/image.svg";
import { ReactComponent as videoCamera } from "../../../assets/common/videoCamera.svg";
import { ReactComponent as editImage } from "../../../assets/common/editImage.svg";
import { ReactComponent as editVideo } from "../../../assets/common/videoEditor.svg";
import { ReactComponent as link } from "../../../assets/common/link.svg";
import { ReactComponent as sort } from "../../../assets/common/sorting.svg";
import { ReactComponent as screenInfo } from "../../../assets/common/screenInfo.svg";

import { ReactComponent as playIcon } from "../../../assets/gamecontroller/play.svg";
import { ReactComponent as disconnect } from "../../../assets/gamecontroller/link.svg";
import { ReactComponent as pauseIcon } from "../../../assets/gamecontroller/pause.svg";
import { ReactComponent as statusIcon } from "../../../assets/gamecontroller/status.svg";
import { ReactComponent as giftIcon } from "../../../assets/gamecontroller/gift.svg";
import { ReactComponent as timerIcon } from "../../../assets/gamecontroller/clock.svg";
import { ReactComponent as roundClock } from "../../../assets/gamecontroller/roundclock.svg";
import { ReactComponent as roundPlusIcon } from "../../../assets/gamecontroller/plus.svg";
import { ReactComponent as roundMinusIcon } from "../../../assets/gamecontroller/minus.svg";
import { ReactComponent as roundStopIcon } from "../../../assets/gamecontroller/stop.svg";
import { ReactComponent as squareIcon } from "../../../assets/gamecontroller/square.svg";
import { ReactComponent as roundPlayIcon } from "../../../assets/gamecontroller/roundPlay.svg";
import { ReactComponent as circleIcon } from "../../../assets/gamecontroller/circle.svg";
import { ReactComponent as presentation } from "../../../assets/gamecontroller/presentation.svg";
import { ReactComponent as broadcast } from "../../../assets/gamecontroller/broadcast.svg";
import { ReactComponent as emailSent } from "../../../assets/gamecontroller/mailSent.svg";
import { ReactComponent as emailError } from "../../../assets/gamecontroller/mailError.svg";

import { ReactComponent as communityTrust } from "../../../assets/summary/communityTrust.svg";
import { ReactComponent as creditRating } from "../../../assets/summary/creditRating.svg";
import { ReactComponent as customerComplaints } from "../../../assets/summary/customerComplaints.svg";
import { ReactComponent as customerSatisfaction } from "../../../assets/summary/customerSatisfaction.svg";
import { ReactComponent as employeeEngagement } from "../../../assets/summary/employeeEngagement.svg";
import { ReactComponent as loanImpairment } from "../../../assets/summary/loanImpairment.svg";
import { ReactComponent as regulatorSanctions } from "../../../assets/summary/regulatorSanctions.svg";
import { ReactComponent as returnOnEquity } from "../../../assets/summary/returnOnEquity.svg";
import { ReactComponent as risk } from "../../../assets/summary/risk.svg";
import { ReactComponent as shareHolderReturn } from "../../../assets/summary/shareHolderReturn.svg";

import { ReactComponent as npatGrowth } from "../../../assets/financial/npatGrowth.svg";
import { ReactComponent as nim } from "../../../assets/financial/nim.svg";
import { ReactComponent as costOfFunds } from "../../../assets/financial/costOfFunds.svg";
import { ReactComponent as costToIncome } from "../../../assets/financial/costToIncome.svg";
import { ReactComponent as capitalRatio } from "../../../assets/financial/capitalRatio.svg";
import { ReactComponent as remediationAndFines } from "../../../assets/financial/remediationAndFines.svg";

import { ReactComponent as homeLoans } from "../../../assets/business/homeLoans.svg";
import { ReactComponent as businessLoans } from "../../../assets/business/businessLoans.svg";
import { ReactComponent as creditCards } from "../../../assets/business/creditCards.svg";
import { ReactComponent as deposits } from "../../../assets/business/deposits.svg";
import { ReactComponent as wealthManagement } from "../../../assets/business/wealthManagement.svg";
import { ReactComponent as institutionalBanking } from "../../../assets/business/institutionalBanking.svg";
import { ReactComponent as offshoreBank } from "../../../assets/business/offshoreBank.svg";

import { ReactComponent as goldMedal } from "../../../assets/results/goldMedal.svg";
import { ReactComponent as silverMedal } from "../../../assets/results/silverMedal.svg";
import { ReactComponent as bronzeMedal } from "../../../assets/results/bronzeMedal.svg";

import { ReactComponent as citizens } from "../../../assets/results/citizens.svg";
import { ReactComponent as casting } from "../../../assets/results/casting.svg";
import { ReactComponent as stopcasting } from "../../../assets/results/stopcasting.svg";
import { ReactComponent as dollars } from "../../../assets/results/dollars.svg";
import { ReactComponent as hammer } from "../../../assets/results/hammer.svg";
import { ReactComponent as scales } from "../../../assets/results/scales.svg";
import { ReactComponent as sayno } from "../../../assets/results/say-no.svg";
import { ReactComponent as thankyoucircle } from "../../../assets/results/thankyoucircle.svg";
import { ReactComponent as ladythankyou } from "../../../assets/results/ladythankyou.svg";
import { ReactComponent as lets_go } from "../../../assets/results/lets_go.svg";
import { ReactComponent as depressedman } from "../../../assets/results/depressedman.svg";
import { ReactComponent as neutralman } from "../../../assets/results/neutralman.svg";
import { ReactComponent as happyman } from "../../../assets/results/happyman.svg";
import { ReactComponent as clock } from "../../../assets/results/clock.svg";
import { ReactComponent as diamond } from "../../../assets/results/diamond.svg";
import { ReactComponent as circleWithArrows } from "../../../assets/results/circleWithArrows.svg";
import { ReactComponent as player } from "../../../assets/results/player.svg";
import { ReactComponent as playerWithHighlight } from "../../../assets/results/playerWithHighlight.svg";
import { ReactComponent as tablet } from "../../../assets/results/tablet.svg";
import { ReactComponent as thumbsUp } from "../../../assets/results/thumbsup.svg";
import { ReactComponent as rocketLady } from "../../../assets/results/rocketlady.svg";
import { ReactComponent as cogs } from "../../../assets/results/cogs.svg";
import { ReactComponent as graph } from "../../../assets/results/graph.svg";
import { ReactComponent as firstPlace } from "../../../assets/results/firstplace.svg";
import { ReactComponent as target } from "../../../assets/results/target.svg";
import { ReactComponent as gameParameters } from "../../../assets/results/gameParameters.svg";
import { ReactComponent as growthFunding } from "../../../assets/results/growthfunding.svg";

import { ReactComponent as newsBear } from "../../../assets/results/newsBear.svg";
import { ReactComponent as newsBull } from "../../../assets/results/newsBull.svg";
import { ReactComponent as newsGlobe } from "../../../assets/results/newsGlobe.svg";
import { ReactComponent as bank } from "../../../assets/results/bank.svg";
import { ReactComponent as emptyPockets } from "../../../assets/results/emptyPockets.svg";
import { ReactComponent as slide } from "../../../assets/results/slide.svg";

import { ReactComponent as report } from "../../../assets/common/report.svg";
import { ReactComponent as assignTablet } from "../../../assets/tabletConfig/assignTablet.svg";
import { ReactComponent as editTablet } from "../../../assets/tabletConfig/editTablet.svg";
import { ReactComponent as authenticate } from "../../../assets/tabletConfig/authenticate.svg";
import { ReactComponent as printParticipants } from "../../../assets/common/printParticipant.svg";
import { ReactComponent as printAttendance } from "../../../assets/common/printAttendance.svg";
import { ReactComponent as wand } from "../../../assets/common/wand.svg";
import { ReactComponent as paperPlane } from "../../../assets/common/paperPlane.svg";
import { ReactComponent as arrowClockwise } from "../../../assets/lexical/arrowClockwise.svg";
import { ReactComponent as arrowCounterClockwise } from "../../../assets/lexical/arrowCounterclockwise.svg";
import { ReactComponent as textCenter } from "../../../assets/lexical/textCenter.svg";
import { ReactComponent as textLeft } from "../../../assets/lexical/textLeft.svg";
import { ReactComponent as textRight } from "../../../assets/lexical/textRight.svg";
import { ReactComponent as textParagraph } from "../../../assets/lexical/textParagraph.svg";
import { ReactComponent as typeBold } from "../../../assets/lexical/typeBold.svg";
import { ReactComponent as typeItalic } from "../../../assets/lexical/typeItalic.svg";
import { ReactComponent as typeUnderline } from "../../../assets/lexical/typeUnderline.svg";
import { ReactComponent as typeStrikethrough } from "../../../assets/lexical/typeStrikethrough.svg";
import { ReactComponent as fontColour } from "../../../assets/lexical/fontColour.svg";
import { ReactComponent as chevronDown } from "../../../assets/lexical/chevronDown.svg";
import { ReactComponent as bgColour } from "../../../assets/lexical/bgColour.svg";
import { ReactComponent as dropdownMore } from "../../../assets/lexical/dropdownMore.svg";
import { ReactComponent as thankyou } from "../../../assets/results/OneBigSVG.svg";
import { ReactComponent as calender } from "../../../assets/results/calender.svg";
import { ReactComponent as climb } from "../../../assets/results/climb.svg";
import { ReactComponent as puzzleMan } from "../../../assets/results/puzzleman.svg";
import { ReactComponent as dollar } from "../../../assets/results/dollar.svg";
import { ReactComponent as turnOver } from "../../../assets/results/turnover.svg";
import { ReactComponent as marketGrowth } from "../../../assets/results/marketgrowth.svg";
import { ReactComponent as investment } from "../../../assets/results/investment.svg";
import { ReactComponent as lightBulb } from "../../../assets/results/lightbulb.svg";
import { ReactComponent as newRate } from "../../../assets/results/newRate.svg";
import { ReactComponent as income } from "../../../assets/results/income.svg";
import { ReactComponent as growth } from "../../../assets/results/growth.svg";
import { ReactComponent as impact } from "../../../assets/results/impact.svg";
import { ReactComponent as currentRate } from "../../../assets/results/currentRate.svg";
import { ReactComponent as rightBlueArrow } from "../../../assets/results/rightBlueArrow.svg";
import { ReactComponent as topGreenArrow } from "../../../assets/results/TopGreenArrow.svg";
import { ReactComponent as bottomYellowArrow } from "../../../assets/results/bottomYellowArrow.svg";
import { ReactComponent as homeLoanCard } from "../../../assets/results/homeloanCard.svg";
import { ReactComponent as cube } from "../../../assets/results/cube.svg";
import { ReactComponent as dividend } from "../../../assets/results/dividend.svg";
import { ReactComponent as buyBacks } from "../../../assets/results/buybacks.svg";
import { ReactComponent as topArrow } from "../../../assets/results/toparrow.svg";
import { ReactComponent as bottomArrow } from "../../../assets/results/bottom-arrow.svg";
import { ReactComponent as leftArrow } from "../../../assets/results/leftarrow.svg";
import { ReactComponent as buybacks } from "../../../assets/results/buybacks.svg";
import { ReactComponent as sharePrice } from "../../../assets/results/shareprice.svg";
import { ReactComponent as roomsTimer } from "../../../assets/results/timer.svg";
import { ReactComponent as mainRoom } from "../../../assets/results/mainroom.svg";
import { ReactComponent as breakout } from "../../../assets/results/breakout.svg";
import { ReactComponent as strengthScales } from "../../../assets/results/strengthScales.svg";
import { ReactComponent as strengthScalesBottom } from "../../../assets/results/strengthScalesBottom.svg";
import { ReactComponent as pointer } from "../../../assets/results/pointer.svg";
import { ReactComponent as syncing } from "../../../assets/results/syncing.svg";
import { ReactComponent as ceo } from "../../../assets/results/ceo.svg";
import { ReactComponent as infoContainer } from "../../../assets/results/infoContainer.svg";

import { ReactComponent as costDiscipline } from "../../../assets/results/costDiscipline.svg";
import { ReactComponent as customerFocus } from "../../../assets/results/customerFocus.svg";
import { ReactComponent as funding } from "../../../assets/results/funding.svg";
import { ReactComponent as investments } from "../../../assets/results/investments.svg";
import { ReactComponent as liquidity } from "../../../assets/results/liquidity.svg";
import { ReactComponent as manageRisk } from "../../../assets/results/manageRisk.svg";
import { ReactComponent as margins } from "../../../assets/results/margins.svg";
import { ReactComponent as operationalRisk } from "../../../assets/results/operationalRisk.svg";
import { ReactComponent as pricing } from "../../../assets/results/pricing.svg";
import { ReactComponent as shareholderReturns } from "../../../assets/results/shareholderReturns.svg";
import { ReactComponent as outcomeOne } from "../../../assets/results/outcome1.svg";
import { ReactComponent as outcomeTwo } from "../../../assets/results/outcome2.svg";
import { ReactComponent as outcomeThree } from "../../../assets/results/outcome3.svg";
import { ReactComponent as outcomeFour } from "../../../assets/results/outcome4.svg";
import { ReactComponent as outcomeRocket } from "../../../assets/results/outcomeRocket.svg";
import { ReactComponent as fullScreen } from "../../../assets/results/fullScreen.svg";
import { ReactComponent as Minimize } from "../../../assets/results/minimize-sign.svg";
import { ReactComponent as editRow } from "../../../assets/results/editTable.svg";
import { ReactComponent as notes } from "../../../assets/results/notes.svg";
import { ReactComponent as rocket } from "../../../assets/event/rocket.svg";
import { ReactComponent as aws } from "../../../assets/event/aws.svg";
import { ReactComponent as excel } from "../../../assets/event/excel.svg";
import { ReactComponent as build } from "../../../assets/event/hammer.svg";
import { ReactComponent as loadtest } from "../../../assets/common/loadtest.svg";

import "./Icon.scss";
import Theme from "../../../styles/_theme.module.scss";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";
import { ResponsiveSize } from "../image/Image";

export const mapTypeToIcon = {
  assessments: assessmentsIcon,
  branding: brandingIcon,
  email: emailIcon,
  events: eventsIcon,
  settings: settingsIcon,
  simulations: simulationsIcon,
  schedule: scheduleIcon,
  pencil: pencilIcon,
  ban: banIcon,
  trash: trashIcon,
  copy: copyIcon,
  clipboard,
  clone: cloneIcon,
  close: closeIcon,
  economic,
  edit: editIcon,
  key: keyIcon,
  tick: tickIcon,
  lock: lockIcon,
  maximize,
  lockOpen: lockOpenIcon,
  lockClosed: lockClosedIcon,
  unlock: unlockIcon,
  idea: ideaIcon,
  mail: mailIcon,
  upload: uploadIcon,
  home: homeIcon,
  search: searchIcon,
  filter: filterIcon,
  star: starIcon,
  users: usersIcon,
  clients: clientsIcon,
  disk: diskIcon,
  addUser: addUserIcon,
  addEvent: addEventIcon,
  export: exportIcon,
  import: importIcon,
  plus: plusIcon,
  gameController: gameControllerIcon,
  play: playIcon,
  disconnect,
  pause: pauseIcon,
  status: statusIcon,
  gift: giftIcon,
  timer: timerIcon,
  roundClock,
  arrow: arrowIcon,
  roundPlus: roundPlusIcon,
  roundMinus: roundMinusIcon,
  roundStop: roundStopIcon,
  square: squareIcon,
  roundPlay: roundPlayIcon,
  circle: circleIcon,
  info: infoIcon,
  draggable: draggableIcon,
  audit: auditLogIcon,
  tabletConfig: tabletConfig,
  leftChevron: leftChevron,
  rightChevron,
  assignTablet: assignTablet,
  editTablet: editTablet,
  printParticipants: printParticipants,
  printAttendance: printAttendance,
  excelFile: ExcelFile,

  textSize: TextSize,
  cast,

  arrowClockwise: arrowClockwise,
  arrowCounterClockwise: arrowCounterClockwise,
  fontColour: fontColour,
  presenter,
  chevronDown: chevronDown,
  bgColour: bgColour,
  dropdownMore: dropdownMore,
  textCenter,
  textLeft,
  textRight,
  textParagraph,
  typeBold,
  typeItalic,
  typeUnderline,
  typeStrikethrough,

  wand,
  paperPlane,
  communityTrust,
  creditRating,
  customerComplaints,
  customerSatisfaction,
  employeeEngagement,
  loanImpairment,
  regulatorSanctions,
  returnOnEquity,
  risk,
  shareHolderReturn,
  npatGrowth,
  nim,
  costOfFunds,
  costToIncome,
  capitalRatio,
  remediationAndFines,
  preview,
  refresh,
  calculator,
  sculpting,
  treasure,
  eye,
  hidden,
  more,
  compare,
  slider,
  publish,
  group,
  homeLoans,
  businessLoans,
  creditCards,
  deposits,
  wealthManagement,
  institutionalBanking,
  offshoreBank,
  help,
  goldMedal,
  silverMedal,
  bronzeMedal,
  citizens,
  casting,
  stopcasting,
  dollars,
  hammer,
  scales,
  sayno,
  thankyoucircle,
  ladythankyou,
  lets_go,
  depressedman,
  neutralman,
  happyman,
  clock,
  diamond,
  circleWithArrows,
  player,
  playerWithHighlight,
  tablet,
  newsBear,
  newsBull,
  newsGlobe,
  bank,
  emptyPockets,
  slide,
  report,
  error,
  thankyou,
  calender,
  climb,
  costDiscipline,
  customerFocus,
  funding,
  investments,
  liquidity,
  manageRisk,
  margins,
  operationalRisk,
  pricing,
  shareholderReturns,
  outcomeOne,
  outcomeTwo,
  outcomeThree,
  outcomeFour,
  outcomeRocket,
  fullScreen,
  Minimize,
  presentation,
  broadcast,
  font,
  row,
  image,
  videoCamera,
  rocket,
  aws,
  excel,
  build,
  editImage,
  editVideo,
  link,
  editRow,
  sort,
  screenInfo,
  emailSent,
  emailError,
  notes,
  loadtest,
  thumbsUp,
  rocketLady,
  cogs,
  graph,
  firstPlace,
  authenticate,
  target,
  gameParameters,
  dollar,
  puzzleMan,
  turnOver,
  marketGrowth,
  investment,
  lightBulb,
  currentRate,
  rightBlueArrow,
  topGreenArrow,
  bottomYellowArrow,
  homeLoanCard,
  newRate,
  income,
  growth,
  impact,
  growthFunding,
  cube,
  dividend,
  buyBacks,
  topArrow,
  bottomArrow,
  leftArrow,
  buybacks,
  sharePrice,
  roomsTimer,
  mainRoom,
  breakout,
  strengthScales,
  strengthScalesBottom,
  pointer,
  syncing,
  ceo,
  infoContainer,
};

export type IconType = keyof typeof mapTypeToIcon;

export interface Props extends React.SVGProps<SVGSVGElement> {
  type: IconType;
  tip?: {
    id?: string;
    content: string | ReactElement;
  };
  tt?: {
    id: string;
    content?: string;
  };
  active?: boolean;
  className?: string;
  /**
   * @deprecated Use `colour` instead.
   */
  fill?: string;
  hidden?: boolean;
  size?: ThemeSpacing;
  widthSize?: ThemeSpacing;
  heightSize?: ThemeSpacing;
  colour?: ThemeColours;
  noMargin?: boolean;
  skipSizing?: boolean;
  dynamicSize?: ResponsiveSize;
  fullSize?: boolean;
}

function Icon(props: Props) {
  const {
    type,
    tip,
    size,
    widthSize,
    heightSize,
    colour,
    active,
    className,
    fill,
    hidden,
    noMargin,
    skipSizing,
    dynamicSize,
    fullSize,
    stroke,
    tt,
    ...rest
  } = props;
  const SVGIcon = mapTypeToIcon[type];
  const sizeClass = dynamicSize
    ? null
    : size
      ? `size-${size}`
      : skipSizing
        ? null
        : "size-4";
  const widthClass = dynamicSize
    ? null
    : widthSize
      ? `width-${widthSize}`
      : null;
  const heightClass = dynamicSize
    ? null
    : heightSize
      ? `height-${heightSize}`
      : null;
  const colourClass = colour ? `colour-${colour}` : null;
  const strokeClass = stroke ? `stroke-${stroke}` : null;

  let toolTipProps = {};

  if (tt) {
    toolTipProps = {
      "data-tip": tt.content ?? "",
      "data-for": tt.id,
    };
  } else if (tip?.id) {
    // When a id is provided, a new ReactTooltip instance will be created
    // Use it wisely and it may have performance impact when there are many instances in a page
    toolTipProps = {
      "data-tip": "",
      "data-for": tip.id,
    };
  } else {
    toolTipProps = {
      "data-tip": tip?.content,
    };
  }

  const dynamicStyle = {
    ...(dynamicSize
      ? {
          width: `clamp(${dynamicSize.min}, ${dynamicSize.preferred}, ${dynamicSize.max})`,
          height: `clamp(${dynamicSize.min}, ${dynamicSize.preferred}, ${dynamicSize.max})`,
        }
      : {}),
    ...(fullSize
      ? {
          width: "100%",
          height: "100%",
        }
      : {}),
  };

  return (
    <>
      <SVGIcon
        className={classNames(
          "icon",
          { active, hidden, "no-margin": noMargin },
          sizeClass,
          widthClass,
          heightClass,
          colourClass,
          strokeClass,
          [className],
        )}
        fill={fill || Theme.primaryGrey}
        style={dynamicStyle}
        {...toolTipProps}
        {...rest}
      />
      {!!tip?.id && <Tooltip id={tip.id}>{tip.content}</Tooltip>}
    </>
  );
}

export default Icon;
