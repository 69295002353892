import React from "react";
import ParticipantTable, {
  ReportingParticipant,
} from "../components/ParticipantTable";

interface Props {
  participants: ReportingParticipant[];
}

const IntroParticipantSummary: React.FC<Props> = ({ participants }) => {
  return (
    <ParticipantTable
      defaultTitle="Participant Summary"
      participants={participants}
    />
  );
};

export default IntroParticipantSummary;
