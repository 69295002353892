import React, { useCallback, useEffect, useMemo, useRef } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import { percentage } from "../../model/utils/formatters";
import { getLabelFromValue as getCentralBankLabel } from "../../simulations/steps/region/CentralBankDropdown";
import "../RoundResultsTablePage.scss";
import { ThemeColours } from "../../../../types/theme";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";
import newsAudio from "../../../../assets/audio/newsAudio.mp3";
import "./RoundResultsNewsRoom.scss";
import LogoContainer from "../../../LogoContainer/LogoContainer";
import ReportingSubHeading from "../components/ReportingSubHeading";
interface Props {
  round: string;
  data: ModelAPI.Reporting.NewsRoom;
}

const getIconAndColour = (
  newsType: ModelAPI.Reporting.NewsInfoType,
): { icon: IconType; colour: ThemeColours } => {
  if (newsType === "bear") {
    return { icon: "newsBear", colour: "yellow" };
  } else if (newsType === "bull") {
    return { icon: "newsBull", colour: "secondaryBlue" };
  }
  return { icon: "newsBull", colour: "secondaryBlue" };
};

interface IconAndTextProps {
  icon: IconType;
  colour: ThemeColours;
  title: string;
  description: string;
  textAlign: "left" | "right";
}

const IconAndText: React.FC<IconAndTextProps> = ({
  icon,
  colour,
  title,
  description,
  textAlign,
}) => {
  return (
    <VerticalGroup wide style={{ textAlign, display: "block" }}>
      <div
        style={{
          marginRight: textAlign === "right" ? "-2%" : undefined,
          marginLeft: textAlign === "left" ? "-3%" : undefined,
          display: "flex",
          justifyContent: textAlign === "right" ? "flex-end" : "flex-start",
        }}
      >
        <LogoContainer circle colour={colour} size={2}>
          <Icon
            type={icon}
            dynamicSize={{ min: "20px", preferred: "4vw", max: "200px" }}
            stroke="white"
          />
        </LogoContainer>
      </div>
      <ResultsText
        size="xl"
        right={textAlign === "right"}
        bold
        className="mb-2"
      >
        {title}
      </ResultsText>
      <ResultsText size="md" right={textAlign === "right"} isHtml>
        {description}
      </ResultsText>
    </VerticalGroup>
  );
};
export const getMaxClicks = (noAnimations?: boolean) => (noAnimations ? 0 : 1);

const RoundResultsNewsRoom: React.FC<Props> = ({ round, data }) => {
  const context = useResultsContext();
  const show = useMemo(() => {
    return context.noAnimations ? true : context.clicks > 0;
  }, [context.clicks, context.noAnimations]);
  const maxClicks = getMaxClicks(context.noAnimations);
  const scenario2IconAndColour = getIconAndColour(data.scenario2Type);
  const scenario3IconAndColour = getIconAndColour(data.scenario3Type);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!context.noAudio) {
      audioRef.current = new Audio(newsAudio);
      audioRef.current.play();
    }
    return () => {
      audioRef.current?.pause();
      audioRef.current = null;
    };
  }, [context.noAudio]);

  const onNext = useCallback(() => {
    if (!context.hidePagination) {
      context.goNextPage();
      return;
    }
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup
        className="round-results-news-room"
        full
        center
        wide
        spaceBetweenElements={2}
      >
        <EditableReportingHeading defaultHeading="News Room" suffix="!" />
        <ReportingSubHeading
          styledUnderline
          subHeading={`Round ${round}: Market Update`}
        />
        <div className={`grid-container ${show ? "show-content" : ""}`}>
          <div className="grid-item scenario1">
            <VerticalGroup>
              <ResultsText size="lg" bold className="mb-2">
                {data.scenario1Title}
              </ResultsText>
              <ResultsText left size="md" isHtml>
                {data.scenario1Desc}
              </ResultsText>
            </VerticalGroup>
          </div>
          <div className="grid-item scenario2">
            <IconAndText
              icon={scenario2IconAndColour.icon}
              colour={scenario2IconAndColour.colour}
              title={data.scenario2Title}
              description={data.scenario2Desc}
              textAlign="left"
            />
          </div>
          <div
            className="grid-item globe"
            onClick={() => context.addClick()}
            style={{ cursor: "pointer" }}
          >
            <VerticalGroup center wide>
              <Icon
                type="newsGlobe"
                colour="blue"
                style={{ width: "100%", height: "100%" }}
              ></Icon>
              {!context.hidePagination && !context.noAnimations && (
                <ResultsText size="sm" center>
                  Click on globe to show news.
                </ResultsText>
              )}
            </VerticalGroup>
          </div>
          <div className="grid-item cash-rate">
            <VerticalGroup>
              <ResultsText size="lg" bold right className="mb-2">
                {getCentralBankLabel(data.centralBank)} Official Cash Rate:{" "}
                {percentage(data.cashRate)}
              </ResultsText>
              <ResultsText size="md" right>
                {data.cashRateDesc}
              </ResultsText>
            </VerticalGroup>
          </div>
          <div className="grid-item scenario3">
            <IconAndText
              icon={scenario3IconAndColour.icon}
              colour={scenario3IconAndColour.colour}
              title={data.scenario3Title}
              description={data.scenario3Desc}
              textAlign="right"
            />
          </div>
        </div>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsNewsRoom;
