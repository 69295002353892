import React from "react";
import classNames from "classnames";

import "./InlineGroup.scss";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  right?: boolean;
  spread?: boolean;
  className?: string;
  verticalCenter?: boolean;
  verticalBottom?: boolean;
  verticalTop?: boolean;
  block?: boolean;
  center?: boolean;
  reverse?: boolean;
  fullHeight?: boolean;
  spaceBetweenElements?: ThemeSpacing;
  evenWidthChildren?: boolean;
  stretch?: boolean;
  "data-test"?: string;
  bgColour?: ThemeColours;
  curved?: boolean;
  minHeight?: string;
  width?: string;
  border?: {
    style: "solid" | "dashed" | "dotted";
    color: ThemeColours;
    width: string;
    rounded?: string;
  };
}

const InlineGroup = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      block,
      center,
      children,
      className,
      reverse,
      right,
      spaceBetweenElements,
      spread,
      stretch,
      verticalCenter,
      verticalBottom,
      verticalTop,
      fullHeight,
      evenWidthChildren,
      bgColour,
      "data-test": dataTest,
      border,
      curved,
      minHeight,
      width,

      ...rest
    },
    ref,
  ) => {
    InlineGroup.displayName = "InlineGroup";
    const spaceBetweenElementsClass = spaceBetweenElements
      ? `space-left-${spaceBetweenElements}`
      : null;
    const bgColourClass = bgColour ? `bg-colour-${bgColour}` : null;
    const borderClass = border
      ? `border-${border.style} border-colour-${border.color} ${
          border.rounded ? "border-rounded" : ""
        }`
      : null;

    const borderStyle = border
      ? {
          borderWidth: border.width,
          borderRadius:
            typeof border.rounded === "string" ? border.rounded : undefined,
        }
      : {};
    const widthStyle = width ? { width } : {};
    const combinedStyles = {
      ...widthStyle,
      ...borderStyle,
      ...(minHeight && { minHeight }),
    };

    return (
      <div
        ref={ref}
        className={classNames(
          "inline-group",
          {
            "align-center": verticalCenter,
            "align-bottom": verticalBottom,
            "align-top": verticalTop,
            block,
            center,
            evenWidthChildren,
            reverse,
            right,
            spread,
            stretch,
            curved,
            fullHeight,
          },
          spaceBetweenElementsClass,
          bgColourClass,
          borderClass,
          className,
        )}
        style={combinedStyles}
        data-test={dataTest}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export default InlineGroup;
