import { PreviewSettings } from "../components/pages/administration/configuration/economicScenarioBuilder/types";
import { get, patch, post, put } from "../lib/request";
import { wait } from "../lib/wait";

const MODEL_HOST = process.env.REACT_APP_MODEL_API_URL?.includes("localhost")
  ? process.env.REACT_APP_MODEL_API_URL.replace(
      "localhost",
      process.env.REACT_APP_LOCAL_IP ?? "localhost",
    )
  : process.env.REACT_APP_MODEL_API_URL;

class ModelAPI {
  token: string;

  constructor() {
    this.token = "";
  }

  headers = () => ({
    Authorization: `Bearer ${this.token}`,
  });

  setToken(token: string) {
    this.token = token;
  }

  getModelConfiguration = async (eventId: string) => {
    const response = await get<ModelAPI.ConfigurationResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/configuration`,
      headers: this.headers(),
    });
    return response.data;
  };

  buildModel = async (eventId: string, uploadInitialResults: boolean) => {
    const data: ModelAPI.ConfigureRequest = {
      type: "CONFIGURE",
      eventId,
      uploadInitialResults,
    };
    const response = await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getModelTeamResults = async (
    eventId: string,
    roundId: number,
    teamId: number,
  ) => {
    const response = await get<ModelAPI.TeamResultsResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/teams/${teamId}/results`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelTeamResultsAll = async (eventId: string, roundId: number) => {
    const response = await get<ModelAPI.RoundResultsResponse[]>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/results`,
      headers: this.headers(),
    });
    return response.data;
  };

  onRecalculateAndReportingAll = async (eventId: string, roundId: number) => {
    for (let round = 1; round <= roundId; round++) {
      const data: ModelAPI.CalculateResultsRequest = {
        type: "CALCULATE_RESULTS",
        eventId,
        roundId: round,
      };

      await post<Record<string, never>>({
        url: `${MODEL_HOST}/api/messages`,
        headers: this.headers(),
        body: data,
      });

      let iterations = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await wait(2000);
        const configuration = await this.getModelConfiguration(eventId);
        if (configuration.state === "round-calculated") {
          break;
        }
        if (iterations > 100) {
          throw new Error("Timedout calculating rounds");
        }
        iterations++;
      }

      const data3: ModelAPI.CalculateReportingRequest = {
        type: "CALCULATE_REPORTING",
        eventId,
        roundId: round,
      };
      await post<Record<string, never>>({
        url: `${MODEL_HOST}/api/messages`,
        headers: this.headers(),
        body: data3,
      });
    }

    return null;
  };

  recalculateAndSculptAllResults = async (eventId: string, roundId: number) => {
    for (let round = 1; round <= roundId; round++) {
      const data: ModelAPI.CalculateAndSculptAndReportingRequest = {
        type: "CALCULATE_AND_SCULPT_AND_REPORTING",
        eventId,
        roundId: round,
      };

      await post<Record<string, never>>({
        url: `${MODEL_HOST}/api/messages`,
        headers: this.headers(),
        body: data,
      });

      let iterations = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await wait(2000);
        const configuration = await this.getModelConfiguration(eventId);
        if (configuration.state === "results-uploaded") {
          break;
        }
        if (iterations > 100) {
          throw new Error("Timedout sculpting all rounds");
        }
        iterations++;
        await wait(2000);
      }
    }

    return null;
  };

  recalculateAllResults = async (eventId: string, roundId: number) => {
    for (let round = 1; round <= roundId; round++) {
      const data: ModelAPI.CalculateResultsRequest = {
        type: "CALCULATE_RESULTS",
        eventId,
        roundId: round,
      };

      await post<Record<string, never>>({
        url: `${MODEL_HOST}/api/messages`,
        headers: this.headers(),
        body: data,
      });

      let iterations = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await wait(2000);
        const configuration = await this.getModelConfiguration(eventId);
        if (configuration.state === "round-calculated") {
          break;
        }
        if (iterations > 100) {
          throw new Error("Timedout calculating rounds");
        }
        iterations++;
      }
    }

    return null;
  };

  recalculateResults = async (eventId: string, roundId: number) => {
    const data: ModelAPI.CalculateResultsRequest = {
      type: "CALCULATE_RESULTS",
      eventId,
      roundId,
    };
    await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });

    let iterations = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await wait(2000);
      const configuration = await this.getModelConfiguration(eventId);
      if (configuration.state === "round-calculated") {
        break;
      }
      if (iterations > 100) {
        throw new Error("Timedout calculating rounds");
      }
      iterations++;
    }
    return null;
  };

  recalculateAll = async (eventId: string, roundId: number) => {
    const data: ModelAPI.CalculateAndSculptAndReportingRequest = {
      type: "CALCULATE_AND_SCULPT_AND_REPORTING",
      eventId,
      roundId,
    };
    await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
  };

  uploadResults = async (eventId: string, roundId: number) => {
    const data: ModelAPI.UploadResultsRequest = {
      type: "UPLOAD_RESULTS",
      eventId,
      roundId,
    };
    const response = await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  recalculateReporting = async (eventId: string, roundId: number) => {
    const data: ModelAPI.CalculateReportingRequest = {
      type: "CALCULATE_REPORTING",
      eventId,
      roundId,
    };
    const response = await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  sculptResults = async (eventId: string, roundId: number) => {
    const data: ModelAPI.SculptTreasuryRequest = {
      type: "SCULPT_TREASURY",
      eventId,
      roundId,
    };
    const response = await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getDecisions = async (eventId: string, roundId: number) => {
    const response = await get<ModelAPI.DecisionsResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/decisions`,
      headers: this.headers(),
    });
    return response.data;
  };

  getMarketShare = async (eventId: string, roundId: number) => {
    const response = await get<ModelAPI.MarketShareResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/marketshare`,
      headers: this.headers(),
    });
    return response.data;
  };

  getReportingResults = async (eventId: string, roundId: number) => {
    const response = await get<ModelAPI.Reporting.ReportingResults>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/reporting`,
      headers: this.headers(),
    });
    return response.data;
  };

  getPublishedReportingResults = async (eventId: string, id: string) => {
    const response = await get<ModelAPI.Reporting.ReportingResults>({
      url: `${MODEL_HOST}/api/events/${eventId}/reporting/${id}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getReportingResultsForCast = async (eventId: string, castKey: string) => {
    const response = await get<ModelAPI.Reporting.ReportingResults>({
      url: `${MODEL_HOST}/api/events/${eventId}/reporting/cast/${castKey}`,
    });
    return response.data;
  };

  getModelVarianceChecks = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/variance`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelSummarySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/summary`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelFinancialsSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/financials`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelOpexSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/opex`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelTreasurySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/treasury`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelPeerInsightsSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/peer-insights`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelBusinessSetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/business`,
      headers: this.headers(),
    });
    return response.data;
  };

  getModelFinancialSummarySetup = async (eventId: string, roundId: number) => {
    const response = await get<any>({
      url: `${MODEL_HOST}/api/game-results/events/${eventId}/rounds/${roundId}/financials/summary`,
      headers: this.headers(),
    });
    return response.data;
  };

  getDefaultLayouts = async (
    reportingTemplateId: string,
    simulationId: string,
  ) => {
    const response = await get<API.ReportingTemplateLayoutResponse[]>({
      url: `${MODEL_HOST}/api/reporting-templates/${reportingTemplateId}/default-layouts?simulationId=${simulationId}`,
      headers: this.headers(),
    });
    return response.data;
  };

  getPreviewData = async ({
    reportingTemplateId,
    introTemplateId,
    simulationId,
    roundId,
    brandingId,
  }: {
    reportingTemplateId?: string;
    introTemplateId?: string;
    simulationId: string;
    roundId: number;
    brandingId?: string;
  }) => {
    let url = `${MODEL_HOST}/api/reporting-preview/rounds/${roundId}?simulationId=${simulationId}`;
    if (brandingId != null) {
      url += `&brandingId=${brandingId}`;
    }
    if (introTemplateId != null) {
      url += `&introTemplateId=${introTemplateId}`;
    }
    if (reportingTemplateId != null) {
      url += `&reportingTemplateId=${reportingTemplateId}`;
    }

    const response = await get<ModelAPI.Reporting.ReportingResults>({
      url,
      headers: this.headers(),
    });
    return response.data;
  };

  getGenericPreviewData = async ({
    introTemplateId,
    reportingTemplateId,
    roundId,
    scenarioId,
    previewSettings,
  }: {
    introTemplateId: string | null | undefined;
    reportingTemplateId: string | null | undefined;
    roundId: number;
    scenarioId?: string;
    previewSettings?: PreviewSettings;
  }) => {
    let url = `${MODEL_HOST}/api/reporting-preview?roundId=${roundId}`;
    if (reportingTemplateId) {
      url += `&reportingTemplateId=${reportingTemplateId}`;
    }
    if (introTemplateId) {
      url += `&introTemplateId=${introTemplateId}`;
    }
    if (scenarioId) {
      url += `&scenarioId=${scenarioId}`;
    }
    if (previewSettings) {
      url += `&country=${previewSettings.country}&currency=${previewSettings.currency}&regulator=${previewSettings.regulator}&referenceRate=${previewSettings.referenceRate}&centralBank=${previewSettings.centralBank}`;
    }
    const response = await get<ModelAPI.Reporting.ReportingResults>({
      url,
      headers: this.headers(),
    });
    return response.data;
  };

  publishReporting = async (eventId: string, roundId: number) => {
    const data: ModelAPI.PublishReportingRequest = {
      type: "PUBLISH_REPORTING",
      eventId,
      roundId,
    };
    const response = await post<Record<string, never>>({
      url: `${MODEL_HOST}/api/messages`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getAdjustments = async (eventId: string) => {
    const response = await get<ModelAPI.FacilitatorAdjustmentsResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/facilitator-adjustments`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateAdjustments = async (
    eventId: string,
    roundId: number,
    data: ModelAPI.FacilitatorAdjustmentsUpdateRequest,
  ) => {
    const response = await put<any>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/facilitator-adjustments`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };
  updateMultipleAdjustments = async (
    eventId: string,
    roundId: number,
    data: ModelAPI.FacilitatorAdjustmentsUpdateRequest[],
  ) => {
    const response = await put<any>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/facilitator-adjustments/batch`,
      headers: this.headers(),
      body: { adjustments: data },
    });
    return response.data;
  };

  updateReportingTemplate = async (
    eventId: string,
    roundId: number,
    data: ModelAPI.ReportingUpdateRequest,
  ) => {
    const response = await patch<ModelAPI.Reporting.ReportingResults>({
      url: `${MODEL_HOST}/api/events/${eventId}/rounds/${roundId}/reporting/layouts`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getReportingPresentationState = async (
    eventId: string,
    opts: { bypass: boolean },
  ) => {
    const response = await get<ModelAPI.ReportingPresentationResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/reporting-presentation${opts.bypass ? "?bypass=true" : ""}`,
      headers: this.headers(),
    });
    return response.data;
  };

  updateReportingPresentationState = async (
    eventId: string,
    data: ModelAPI.ReportingPresentationRequest,
  ) => {
    const response = await patch<ModelAPI.ReportingPresentationResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/reporting-presentation`,
      headers: this.headers(),
      body: data,
    });
    return response.data;
  };

  getModelLogs = async (eventId: string) => {
    const response = await get<ModelAPI.EventLogResponse>({
      url: `${MODEL_HOST}/api/events/${eventId}/logs`,
      headers: this.headers(),
    });

    return response.data;
  };

  getDefaultSimulation = async (reportingTemplateId: string) => {
    const response = await get<{ simulationId: string | null }>({
      url: `${MODEL_HOST}/api/reporting-templates/${reportingTemplateId}/default-simulation`,
      headers: this.headers(),
    });

    return response.data;
  };
}

export default new ModelAPI();
