import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import API from "../../../../../../services/api";
import Banner from "../../../../../atoms/banner/Banner";
import Form from "../../../../../atoms/form/Form";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import { EventContext } from "../../context";
import useForm from "../../../../../../hooks/useForm";
import Card from "../../../../../atoms/card/Card";
import Input from "../../../../../atoms/form/input/Input";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import Datepicker from "../../../../../atoms/datepicker/Datepicker";
import ClientDropdown from "../../../../../organisms/client-dropdown/ClientDropdown";
import DisplayField from "../../../../../atoms/form/display-field/DisplayField";
import { convertToYYYYMMDD } from "../../../../../../lib/date";
import EventOptions from "../../components/EventOptions";
import StepContainer from "../../components/StepContainer";
import ConfirmModal from "../../../../../organisms/confirm-modal/ConfirmModal";
import Toggle from "../../../../../atoms/toggle/Toggle";
import Label from "../../../../../atoms/form/label/Label";
import FacilitatorDropdown from "../../../../../organisms/facilitator-dropdown/FacilitatorDropdown";
import FacilitatorHidden from "../../../../../molecules/facilitator-hidden/FacilitatorHidden";
import FacilitatorOnly from "../../../../../molecules/facilitator-only/FacilitatorOnly";
import { formatPersonName } from "../../../../../../lib/formatters";
import EnumDropdown from "../../../../../organisms/enum-dropdown/EnumDropdown";

const videoConferencingToolOptions = [
  { value: null, label: "None" },
  { value: "zoom", label: "Zoom" },
  { value: "microsoft_teams", label: "Microsoft Teams" },
  { value: "webex", label: "Webex" },
  { value: "google_meet", label: "Google Meet" },
];

const Details: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationComplete, setConfirmationComplete] = useState<
    null | (() => Promise<void | false>)
  >(null);
  const history = useHistory();
  const {
    config,
    updateConfig,
    assignOnSubmitFunction,
    updateFormStatus,
    jumpToStepByKey,
    formMode,
  } = useContext(EventContext);
  const [
    { formData, fieldErrors, error, inProgress, formUpdated },
    {
      handleSubmit,
      setField,
      setDateField,
      setDropdownField,
      setFieldSimple,
      setFieldRaw,
    },
  ] = useForm(
    config?.name
      ? {
          ...config,
          date: new Date(config.date),
          facilitatorIds: config.facilitatorIds || [],
        }
      : {
          id: null,
          name: "",
          location: "",
          date: new Date(),
          clientId: null,
          participantsCount: null,
          maskParticipants: false,
          videoConferencingTool: null,
          facilitatorIds: [],
        },
  );

  const {
    id,
    name,
    date,
    clientId,
    streamName,
    videoConferencingTool,
    location,
    participantsCount,
    maskParticipants,
  } = formData;

  const onSubmit = useCallback(
    (removeLinkedResources) => async () => {
      const payload: API.EventRequest = {
        name: name.trim(),
        clientId,
        date: convertToYYYYMMDD(date),
        location,
        streamName,
        videoConferencingTool,
        maskParticipants,
        facilitatorIds: formData.facilitatorIds,
      };
      if (formMode === "create") {
        // This will be a create event form if no `config.id` present
        const response = await API.createEvent(payload);
        history.push(`/events/${response.id}/view?stepKey=branding`);
      } else if (formMode === "edit" && config?.id) {
        const response = await API.editEvent(config.id, {
          ...payload,
          brandingId: config.brandingId,
          schedule: config.schedule,
          simulationId: config.simulationId,
          assessmentId: config.assessmentId,
          reportingTemplateId: config.reportingTemplateId,
        });
        updateConfig(response);
        return response;
      } else if (formMode === "clone" && config) {
        // This is a clone event
        const duplicatePayload = {
          ...payload,
          brandingId: config.brandingId,
          schedule: config.schedule,
          simulationId: config.simulationId,
          assessmentId: config.assessmentId,
          reportingTemplateId: config.reportingTemplateId,
        };
        if (removeLinkedResources) {
          delete duplicatePayload.brandingId;
          delete duplicatePayload.simulationId;
          delete duplicatePayload.assessmentId;
          delete duplicatePayload.reportingTemplateId;
        }
        const response = await API.duplicateEvent(config.id, duplicatePayload);
        updateConfig(response);
        history.push(`/events/${response.id}/view?stepKey=branding`);
      }
    },
    [
      clientId,
      config,
      date,
      formMode,
      history,
      location,
      name,
      streamName,
      maskParticipants,
      videoConferencingTool,
      updateConfig,
      formData.facilitatorIds,
    ],
  );

  const validate = useCallback(() => {
    const errors = [];
    if (!clientId) {
      errors.push({ field: "clientId", message: "Select a client" });
    }
    if (!name) {
      errors.push({ field: "name", message: "Enter an event name" });
    }
    if (!location) {
      errors.push({ field: "location", message: "Enter a location" });
    }
    return errors;
  }, [clientId, location, name]);

  const handleWithWarning = useCallback(
    (e?: React.FormEvent<HTMLFormElement>): Promise<false | void> => {
      if (config && config.clientId !== clientId) {
        setShowConfirmation(true);
        setConfirmationComplete(
          () => () => handleSubmit(onSubmit(true), validate)(e),
        );
        return Promise.resolve(false);
      }
      return handleSubmit(onSubmit(false), validate)(e);
    },
    [config, handleSubmit, onSubmit, clientId, validate],
  );

  const handleConfirm = useCallback(() => {
    confirmationComplete?.();
    setShowConfirmation(false);
    if (formMode !== "clone") {
      jumpToStepByKey("branding");
    }
  }, [confirmationComplete, jumpToStepByKey, formMode]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
    setConfirmationComplete(null);
    if (config && config.clientId) {
      setFieldSimple("clientId")(config.clientId);
    }
  }, [config, setFieldSimple]);

  useEffect(() => {
    assignOnSubmitFunction(handleWithWarning);
  }, [assignOnSubmitFunction, handleWithWarning]);

  useEffect(() => {
    updateFormStatus({
      inProgress: inProgress,
      formUpdated: formUpdated,
    });
  }, [updateFormStatus, inProgress, formUpdated]);

  const onClientChange = useCallback(
    ({ label, value }: { label: string; value: string }) => {
      setDropdownField("clientId")({ label, value });
    },
    [setDropdownField],
  );

  const onVideoConferencingToolChange = useCallback(
    ({ value }: { label: string; value: string | null }) => {
      setFieldRaw("videoConferencingTool", value);
    },
    [setFieldRaw],
  );

  const handleFacilitatorChange = useCallback(
    (facilitatorIds: string[]) => {
      setFieldRaw("facilitatorIds", facilitatorIds);
    },
    [setFieldRaw],
  );
  return (
    <StepContainer>
      {showConfirmation && (
        <ConfirmModal
          isOpen
          onClose={() => setShowConfirmation(false)}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          title="Confirm change of client"
          description={`Changing client will invalidate any config that has been setup for client ${config?.client}`}
          confirmTitle={"Ok"}
        />
      )}
      <Banner type="error" active={!!error} message={error?.message} />
      <InlineGroup spread verticalCenter block>
        <h2>Details</h2>
        <EventOptions showDangerous />
      </InlineGroup>
      <Form wide>
        <Card wide padding={16}>
          <InlineGroup spread evenWidthChildren>
            <VerticalGroup center spaceBetweenElements={4}>
              <Datepicker
                isVerticalLayout
                label="Date *"
                date={date}
                onChange={setDateField("date")}
                error={fieldErrors.date}
              />
              <FacilitatorHidden>
                <ClientDropdown
                  isVerticalLayout
                  label="Client *"
                  clientId={clientId}
                  onChange={onClientChange}
                  error={fieldErrors.clientId}
                />
              </FacilitatorHidden>
              <FacilitatorOnly>
                <Input
                  isVerticalLayout
                  type="text"
                  label="Client"
                  value={config?.client}
                  disabled
                  onChange={setField("client")}
                  error={fieldErrors.client}
                />
              </FacilitatorOnly>
              <Input
                isVerticalLayout
                data-test="location"
                type="text"
                label="Location *"
                value={location}
                onChange={setField("location")}
                error={fieldErrors.location}
              />
              <VerticalGroup spaceBetweenElements={2}>
                <Label>&nbsp;</Label>
                <Toggle
                  isVerticalLayout
                  label="Welcome Page: Show Player Names"
                  checked={!maskParticipants}
                  onUpdate={(val: boolean) =>
                    setFieldRaw("maskParticipants", !val)
                  }
                  helpTitle="Greet players by name"
                  helpDescription={`Enabling this will greet players by name on the waiting room, and also allow players to change CEOs if "Rotate CEO" is enabled in the simulation configuration. Disabling this will not greet players by name or allow them to use the interface to change CEO.`}
                />
              </VerticalGroup>
            </VerticalGroup>
            <VerticalGroup center spaceBetweenElements={4}>
              <Input
                isVerticalLayout
                data-test="name"
                type="text"
                label="Event Name *"
                value={name}
                onChange={setField("name")}
                error={fieldErrors.name}
              />
              <Input
                isVerticalLayout
                data-test="streamName"
                type="text"
                label="Stream Name"
                value={streamName || ""}
                onChange={setField("streamName")}
                error={fieldErrors.streamName}
              />

              <EnumDropdown
                isVerticalLayout
                label="Video Conferencing Tool"
                onChange={onVideoConferencingToolChange}
                options={videoConferencingToolOptions}
                value={videoConferencingTool}
              />
            </VerticalGroup>
          </InlineGroup>
          <hr className="mt-8" />
          <FacilitatorHidden>
            <VerticalGroup
              className="width-50-percent pt-2"
              center
              spaceBetweenElements={4}
            >
              <FacilitatorDropdown
                selectedFacilitatorIds={formData.facilitatorIds}
                onFacilitatorChange={handleFacilitatorChange}
              />
            </VerticalGroup>
          </FacilitatorHidden>
          <FacilitatorOnly>
            <VerticalGroup
              className="width-50-percent pt-2"
              center
              spaceBetweenElements={4}
            >
              <DisplayField
                compact
                isVerticalLayout
                label="Facilitators"
                value={config?.facilitators?.map(formatPersonName).join(", ")}
              />
            </VerticalGroup>
          </FacilitatorOnly>
          {id ? (
            <>
              <hr className="mt-8" />
              <InlineGroup spread evenWidthChildren className="pt-4">
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Event ID"
                    value={id}
                  />
                </VerticalGroup>
                <VerticalGroup center spaceBetweenElements={4}>
                  <DisplayField
                    compact
                    isVerticalLayout
                    label="Participants"
                    value={participantsCount}
                  />
                </VerticalGroup>
              </InlineGroup>
            </>
          ) : null}
        </Card>
      </Form>
    </StepContainer>
  );
};

export default Details;
