import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import "./ReportingTemplate.scss";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import Badge from "../../../atoms/badge/Badge";
import SortableReportingPagePreviewTile from "./SortableReportingTemplateTile";
import ReportingPagePreviewTile, {
  THUMBNAIL_WIDTH,
} from "./ReportingPagePreviewTile";
import modelApi from "../../../../services/modelApi";
import Banner from "../../../atoms/banner/Banner";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsPage from "../../results/RoundResultsPage";
import Clickable from "../../../atoms/clickable/Clickable";
import Icon from "../../../atoms/icon/Icon";
import SpeakerNotes from "../../results/components/SpeakerNotes";
import useOrientation from "../../../../hooks/useOrientation";
import useIsMobileOrTablet from "../../../../hooks/useIsMobileOrTablet";
import { LayoutResponse } from "../types";

interface Props {
  round: number;
  data: ModelAPI.Reporting.ReportingResults;
  mode: "fullscreen" | "thumbnails";
  setMode: (val: "fullscreen" | "thumbnails") => void;
  previewHeight: number;
  previewWidth: number;
  availableHeight: number;
  availableWidth: number;
  editable?: boolean;
  eventId: string;
  showSpeakerNotes: boolean;
}

const SortableReportingTemplatePreviewContent: React.FC<Props> = ({
  eventId,
  data,
  mode,
  round,
  editable,
  availableHeight,
  availableWidth,
  showSpeakerNotes,
  setMode,
}) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const [apiUpdating, setApiUpdating] = useState(false);
  const [activeLayout, setActiveLayout] = useState<LayoutResponse | null>(null);
  const [slides, setSlides] = useState(data.layouts);
  const isMobileOrTablet = useIsMobileOrTablet();
  const { isPortrait } = useOrientation();
  const speakerNotesRef = useRef<React.ElementRef<typeof SpeakerNotes>>(null);
  const [selectedLayout, setSelectedLayout] = useState<LayoutResponse | null>(
    null,
  );
  function handleDragStart(event: any) {
    const { active } = event;
    const slide = slides.find((l) => l.id === active.id);
    if (slide) {
      setActiveLayout(slide);
    }
  }
  useEffect(() => {
    if (mode === "fullscreen" && selectedLayout == null) {
      setSelectedLayout(slides[0]);
    }
  }, [mode, selectedLayout, slides, round]);
  const handleDragEnd = useCallback(
    async (event: any) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const oldIndex = slides.findIndex((l) => l.id === active.id);
        const newIndex = slides.findIndex((l) => l.id === over.id);

        const newSlides = arrayMove(slides, oldIndex, newIndex);
        setSlides(newSlides);
        try {
          setApiUpdating(true);
          await modelApi.updateReportingTemplate(eventId, round, {
            layouts: newSlides.map((l) => ({
              id: l.id,
              overrideDisabled: l.overrideDisabled,
            })),
          });
          setApiUpdating(false);
        } catch (e) {
          setApiUpdating(false);
          setApiError(e.message);
        }
      }

      setActiveLayout(null);
    },
    [eventId, round, slides],
  );

  const onLayoutUpdate = useCallback(
    async (
      layoutId: string,
      data: API.ReportingTemplateLayoutUpdateRequest,
    ) => {
      try {
        setApiUpdating(true);
        const layout = slides.find((l) => l.id === layoutId);
        if (layout) {
          setSelectedLayout(layout);
        }
        setSlides((list) => {
          const result = Array.from(list);
          const index = result.findIndex((r) => r.id === layoutId);
          result[index] = { ...result[index], ...data };
          return result;
        });
        await modelApi.updateReportingTemplate(eventId, round, {
          layouts: slides.map((l) => ({
            id: l.id,
            overrideDisabled:
              l.id === layoutId && data.overrideDisabled != null
                ? data.overrideDisabled
                : l.overrideDisabled,
            speakerNotes:
              l.id === layoutId && data.speakerNotes != null
                ? data.speakerNotes
                : l.speakerNotes,
          })),
        });
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [eventId, round, slides],
  );

  const sensors = useSensors(
    // useSensor(PointerSensor, { activationConstraint: { delay: 50, tolerance: 1, distance: 1 } })
    useSensor(PointerSensor),
  );

  const ContentStyle = useMemo((): React.CSSProperties => {
    if (isMobileOrTablet) {
      return {
        transform: `scale(${0.6})`,
        transformOrigin: "top left",
        width: `${100 / 0.6}%`,
        height: `${100 / 0.6}%`,
        position: "absolute",
        top: 0,
        left: 0,
      };
    } else {
      return {
        transform: `scale(${0.92})`,
        transformOrigin: "top left",
        width: `${100 / 0.92}%`,
        height: `${100 / 0.92}%`,
      };
    }
  }, [isMobileOrTablet]);

  const renderThumbnails = () => (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={slides}>
        <div
          className={classNames(
            isMobileOrTablet
              ? "mobile-reporting-template-select-container"
              : "reporting-template-select-container",
            { scrollable: true },
          )}
        >
          {slides.map((layout, layoutIndex) => (
            <VerticalGroup wide key={layout.id + layout.overrideDisabled}>
              <Clickable
                style={{ width: "100%" }}
                onClick={() => {
                  setSelectedLayout(layout);
                  setMode("fullscreen");
                }}
              >
                <SortableReportingPagePreviewTile
                  key={layout.id + layout.overrideDisabled}
                  id={layout.id}
                  data={{ ...data, layouts: [layout] }}
                  roundNumber={round}
                  layout={layout}
                  showHeader
                  showTeamPositions
                  overlay={
                    <Badge
                      value={layoutIndex + 1}
                      colour="green"
                      size={24}
                      textSize="5xl"
                      floating
                    />
                  }
                  onLayoutUpdate={onLayoutUpdate}
                  disabled={apiUpdating}
                  useOverrideDisabled
                />
              </Clickable>
              {showSpeakerNotes && (
                <div
                  style={{
                    width: isMobileOrTablet ? "100%" : THUMBNAIL_WIDTH,
                    minHeight: "150px",
                    height: "100%",
                  }}
                >
                  <SpeakerNotes
                    ref={speakerNotesRef}
                    initialValue={layout.speakerNotes || ""}
                    onSave={(newNotes: string) =>
                      onLayoutUpdate(layout.id, { speakerNotes: newNotes })
                    }
                    placeHolderColour="primaryDark"
                  />
                </div>
              )}
            </VerticalGroup>
          ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeLayout && (
          <ReportingPagePreviewTile
            key={activeLayout.id}
            id={activeLayout.id}
            data={{ ...data, layouts: [activeLayout] }}
            roundNumber={round}
            layout={activeLayout}
            showHeader
            showTeamPositions
          />
        )}
      </DragOverlay>
    </DndContext>
  );

  const renderFullscreen = () => {
    if (isPortrait) {
      return (
        <VerticalGroup className="landscape-prompt">
          <Icon size={8} type="refresh" />
          <p>
            Please rotate your device to landscape mode for the best viewing
            experience.
          </p>
        </VerticalGroup>
      );
    }

    return (
      <div
        style={{
          height: availableHeight,
          width: availableWidth,
          background: isMobileOrTablet ? "" : "#e1e1e1",
        }}
      >
        <div style={ContentStyle}>
          <RoundResultsPage
            data={{ ...data, layouts: slides }}
            roundNumber={round}
            onlyThesePages={[]}
            startingLayout={selectedLayout?.type || slides[0].type}
            onLayoutUpdate={onLayoutUpdate}
            noAudio
            allowHiddenLayouts
          />
        </div>
      </div>
    );
  };
  return (
    <div style={{ overflow: "hidden" }}>
      {apiError && (
        <Banner type="error" active={!!apiError} message={apiError} />
      )}
      {mode === "thumbnails" && !editable && renderThumbnails()}
      {mode === "fullscreen" &&
        availableHeight > 0 &&
        availableWidth > 0 &&
        renderFullscreen()}
    </div>
  );
};

export default memo(SortableReportingTemplatePreviewContent);
