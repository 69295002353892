import React from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import VirtualMeetingIntro from "../components/VirtualMeetingIntro";
import meetLogistics from "../../../../assets/results/zoom.png";

const IntroGoogleMeet = () => {
  return (
    <RoundResultsContainer>
      <VirtualMeetingIntro
        defaultHeading="Google Meet Logistics"
        imageSrc={meetLogistics}
        steps={[
          "Please minimize background noise by using mute.",
          "Feel free to use the chat function for questions / comments.",
          "Use the chat if you need our assistance",
          "We'd love to see you on camera, although we'll ask you to turn off when sharing videos.",
        ]}
      />
    </RoundResultsContainer>
  );
};

export default IntroGoogleMeet;
