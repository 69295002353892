import React from "react";
import ParticipantTable, {
  ReportingParticipant,
} from "../components/ParticipantTable";

interface Props {
  participants: ReportingParticipant[];
}

const IntroCheckTeam: React.FC<Props> = ({ participants }) => {
  return (
    <ParticipantTable
      defaultTitle="Check You Are In The Correct Team"
      participants={participants}
    />
  );
};

export default IntroCheckTeam;
