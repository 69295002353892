import React from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import teamsLogistics from "../../../../assets/results/teamsLogistics.png";
import VirtualMeetingIntro from "../components/VirtualMeetingIntro";

const IntroMicrosoftTeams = () => {
  return (
    <RoundResultsContainer>
      <VirtualMeetingIntro
        defaultHeading="Microsoft Teams Logistics"
        imageSrc={teamsLogistics}
        steps={[
          "Please minimize background noise by using mute.",
          "Feel free to use the chat function for questions / comments.",
          "Raise your hand if you need our assistance",
          "We'd love to see you on camera!",
        ]}
      />
    </RoundResultsContainer>
  );
};

export default IntroMicrosoftTeams;
