import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import RoundResultsPage from "../results/RoundResultsPage";
import Button from "../../atoms/button/Button";
import useElementSize from "../../../hooks/useElementSize";
import Clickable from "../../atoms/clickable/Clickable";
import ContentStretcher from "./components/ContentStretcher";
import ReportingPagePreviewTile, {
  RENDERED_DIMENSIONS,
} from "./components/ReportingPagePreviewTile";
import { EditRoundRestrictions } from "./actions/EditRoundRestrictions";
import Text from "../../atoms/text/Text";
import Toggle from "../../atoms/toggle/Toggle";
import { DurationEstimateEdit } from "./actions/DurationEstimateEdit";
import Icon from "../../atoms/icon/Icon";
import SpeakerNotes from "../results/components/SpeakerNotes";
import { LayoutResponse, LayoutUpdateRequest } from "./types";

const SIDEBAR_WIDTH_EXPANDED = 230;
const SIDEBAR_WIDTH_COLLAPSED = 30;
const HEADING_HEIGHT = 64;
const THUMBNAIL_COLUMN_WIDTH = 320;

interface Props {
  droppableId: string;
  onDragEnd: (result: DropResult) => Promise<void>;
  layouts: LayoutResponse[];
  selectedLayout: LayoutResponse;
  setSelectedLayout: (layout: LayoutResponse) => void;
  previewData: ModelAPI.Reporting.ReportingResults;
  disabled?: boolean;
  onLayoutUpdate: (layoutId: string, data: LayoutUpdateRequest) => void;
  onCustomTemplateEdit?: (data: LayoutUpdateRequest) => void;
  onDeleteLayoutClick?: () => void;
  hideDurationEstimateFirstRound?: boolean;
  hideRoundRestrictions?: boolean;
}

function ReportingTemplateDetailsLayout({
  droppableId,
  onDragEnd,
  layouts,
  selectedLayout,
  setSelectedLayout,
  previewData,
  disabled,
  onLayoutUpdate,
  onCustomTemplateEdit,
  onDeleteLayoutClick,
  hideDurationEstimateFirstRound = false,
  hideRoundRestrictions = false,
}: Props) {
  const [isControlsCollapsed, setIsControlsCollapsed] = useState(false);

  const sidebarWidth = isControlsCollapsed
    ? SIDEBAR_WIDTH_COLLAPSED
    : SIDEBAR_WIDTH_EXPANDED;
  const [containerRef, { height: containerHeight, width: containerWidth }] =
    useElementSize();

  const { previewHeight, previewWidth } = useMemo(() => {
    if (containerHeight) {
      const heightAvailable = containerHeight;
      const widthAvailable =
        containerWidth - THUMBNAIL_COLUMN_WIDTH - sidebarWidth;

      const scale = Math.min(
        1,
        Math.min(
          widthAvailable / RENDERED_DIMENSIONS.width,
          heightAvailable / RENDERED_DIMENSIONS.height,
        ),
      );

      return {
        previewHeight: scale * RENDERED_DIMENSIONS.height,
        previewWidth: scale * RENDERED_DIMENSIONS.width,
      };
    }
    return { previewHeight: 0, previewWidth: 0 };
  }, [containerHeight, containerWidth, sidebarWidth]);
  const speakerNotesHeight = isControlsCollapsed ? "20%" : "40%";

  return (
    <InlineGroup
      ref={containerRef}
      className="mb-4"
      fullHeight
      spaceBetweenElements={2}
    >
      {containerHeight > 0 && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId}>
              {(provided) => (
                <VerticalGroup
                  spaceBetweenElements={2}
                  style={{
                    overflowY: "auto",
                    maxHeight: containerHeight - HEADING_HEIGHT,
                    width: THUMBNAIL_COLUMN_WIDTH + 20,
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {layouts.map((l, index) => (
                    <Draggable key={l.id} draggableId={l.id} index={index}>
                      {(provided) => {
                        return (
                          <Clickable
                            // @ts-expect-error ref does exist
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style }}
                            onClick={() => setSelectedLayout(l)}
                            {...provided.draggableProps}
                          >
                            <ReportingPagePreviewTile
                              data={{
                                ...previewData,
                                layouts: [{ ...l, enabled: true }],
                              }}
                              disabled={disabled}
                              layout={l}
                              onLayoutUpdate={onLayoutUpdate}
                              dragHandleProps={
                                provided.dragHandleProps || undefined
                              }
                              thumbnailWidth={320}
                              thumbnailHeight={180}
                            />
                          </Clickable>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </VerticalGroup>
              )}
            </Droppable>
          </DragDropContext>
          <InlineGroup
            style={{
              flex: 1,
            }}
          >
            {selectedLayout && (
              <InlineGroup
                style={{
                  flex: 1,
                }}
                spread
                center
              >
                {previewHeight && previewWidth && (
                  <ContentStretcher
                    style={{
                      background: "#e1e1e1",
                      flex: 1,
                      overflow: "auto",
                      flexDirection: "column",
                    }}
                    contentWidth={RENDERED_DIMENSIONS.width}
                    contentHeight={RENDERED_DIMENSIONS.height}
                  >
                    <div
                      style={{
                        width: RENDERED_DIMENSIONS.width,
                        height: RENDERED_DIMENSIONS.height,
                        minHeight: RENDERED_DIMENSIONS.height,
                        position: "absolute",
                      }}
                    >
                      <RoundResultsPage
                        key={selectedLayout.id}
                        data={{
                          ...previewData,
                          layouts: [{ ...selectedLayout, enabled: true }],
                        }}
                        roundNumber={previewData.roundId}
                        onlyThesePages={[selectedLayout?.type]}
                        hidePagination
                        hideTeamPositions
                        allowCustomTemplateEdits
                        onCustomTemplateEdit={onCustomTemplateEdit}
                        ignoreKeyPress
                        noAudio
                        noAnimations
                      />
                      <VerticalGroup
                        spaceBetweenElements={2}
                        wide
                        style={{ height: speakerNotesHeight }}
                        className=" mt-4"
                      >
                        <Text size="xl" bold>
                          Speaker Notes:
                        </Text>
                        <SpeakerNotes
                          initialValue={selectedLayout.speakerNotes || ""}
                          onSave={(value) =>
                            onLayoutUpdate(selectedLayout.id, {
                              speakerNotes: value,
                            })
                          }
                          placeHolderColour="primaryDark"
                        />
                      </VerticalGroup>
                    </div>
                  </ContentStretcher>
                )}
                <VerticalGroup
                  className="p-2"
                  style={{
                    width: isControlsCollapsed
                      ? SIDEBAR_WIDTH_COLLAPSED
                      : SIDEBAR_WIDTH_EXPANDED,
                    transition: "width 0.5s ease",
                  }}
                >
                  <InlineGroup block>
                    <Clickable
                      onClick={() =>
                        setIsControlsCollapsed(!isControlsCollapsed)
                      }
                    >
                      {" "}
                      <InlineGroup spread block verticalCenter>
                        <Text bold>
                          {isControlsCollapsed ? null : "Slide Controls"}
                        </Text>
                        <div>
                          <Icon
                            size={5}
                            type={
                              isControlsCollapsed
                                ? "leftChevron"
                                : "rightChevron"
                            }
                          />
                        </div>
                      </InlineGroup>
                    </Clickable>
                  </InlineGroup>
                  {!isControlsCollapsed && (
                    <>
                      <VerticalGroup wide spaceBetweenElements={2}>
                        {!hideDurationEstimateFirstRound && (
                          <DurationEstimateEdit
                            field="durationEstimateFirstRound"
                            layout={selectedLayout}
                            onLayoutUpdate={onLayoutUpdate}
                          />
                        )}
                        <DurationEstimateEdit
                          field="durationEstimate"
                          layout={selectedLayout}
                          onLayoutUpdate={onLayoutUpdate}
                        />
                        <VerticalGroup spaceBetweenElements={2} wide>
                          {onCustomTemplateEdit &&
                            selectedLayout.type === "custom_image" &&
                            selectedLayout.config.type === "custom_image" &&
                            selectedLayout.config.imageUrl != null && (
                              <Button
                                disabled={disabled}
                                onClick={() =>
                                  onCustomTemplateEdit({
                                    config: {
                                      type: "custom_image",
                                      heading:
                                        selectedLayout.config.heading ?? "",
                                    },
                                  })
                                }
                              >
                                Remove Image
                              </Button>
                            )}

                          {onCustomTemplateEdit &&
                            selectedLayout.type ===
                              "custom_text_and_image_left" &&
                            selectedLayout.config.type ===
                              "custom_text_and_image_left" &&
                            selectedLayout.config.imageUrl != null && (
                              <Button
                                disabled={disabled}
                                onClick={() =>
                                  onCustomTemplateEdit({
                                    config: {
                                      type: "custom_text_and_image_left",
                                      heading:
                                        selectedLayout.config.heading ?? "",
                                      body:
                                        (
                                          selectedLayout.config as API.ReportingLayoutCustomTextAndImageLeftConfig
                                        ).body ?? "",
                                    },
                                  })
                                }
                              >
                                Remove Image
                              </Button>
                            )}

                          {onCustomTemplateEdit &&
                            selectedLayout.type ===
                              "custom_text_and_image_right" &&
                            selectedLayout.config.type ===
                              "custom_text_and_image_right" &&
                            selectedLayout.config.imageUrl != null && (
                              <Button
                                disabled={disabled}
                                onClick={() =>
                                  onCustomTemplateEdit({
                                    config: {
                                      type: "custom_text_and_image_right",
                                      heading:
                                        selectedLayout.config.heading ?? "",
                                      body:
                                        (
                                          selectedLayout.config as API.ReportingLayoutCustomTextAndImageRightConfig
                                        ).body ?? "",
                                    },
                                  })
                                }
                              >
                                Remove Image
                              </Button>
                            )}

                          {onCustomTemplateEdit &&
                            selectedLayout.type === "custom_video" &&
                            selectedLayout.config.type === "custom_video" &&
                            selectedLayout.config.videoUrl != null && (
                              <Button
                                disabled={disabled}
                                onClick={() =>
                                  onCustomTemplateEdit({
                                    config: {
                                      type: "custom_video",
                                      heading:
                                        selectedLayout.config.heading ?? "",
                                    },
                                  })
                                }
                              >
                                Remove Video
                              </Button>
                            )}

                          <InlineGroup
                            spaceBetweenElements={2}
                            verticalCenter
                            block
                            spread
                          >
                            <InlineGroup>
                              <Text>Enabled:</Text>
                              {disabled && <LoadingSpinner />}
                            </InlineGroup>
                            {!disabled && (
                              <Toggle
                                checked={selectedLayout.enabled}
                                onUpdate={() =>
                                  onLayoutUpdate(selectedLayout.id, {
                                    enabled: !selectedLayout.enabled,
                                  })
                                }
                              />
                            )}
                          </InlineGroup>

                          {onDeleteLayoutClick &&
                            [
                              "custom_image",
                              "custom_video",
                              "custom_text",
                              "custom_text_and_image_left",
                              "custom_text_and_image_right",
                              "custom_slide",
                            ].includes(selectedLayout.type) && (
                              <Button danger onClick={onDeleteLayoutClick}>
                                Delete
                              </Button>
                            )}
                        </VerticalGroup>

                        {!hideRoundRestrictions &&
                          [
                            "custom_image",
                            "custom_video",
                            "custom_text",
                            "custom_text_and_image_left",
                            "custom_text_and_image_right",
                            "custom_slide",
                          ].includes(selectedLayout.type) && (
                            <EditRoundRestrictions
                              layout={selectedLayout}
                              onLayoutUpdate={onLayoutUpdate}
                            />
                          )}
                      </VerticalGroup>
                    </>
                  )}
                </VerticalGroup>
              </InlineGroup>
            )}
          </InlineGroup>
        </>
      )}
    </InlineGroup>
  );
}

export default ReportingTemplateDetailsLayout;
