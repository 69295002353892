import React, { useCallback } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import "./IntroMissionObjectives.scss";
import { useResultsContext } from "../context/context";
import EditableReportingHeading from "../components/EditableReportingHeading";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ReportingSubHeading from "../components/ReportingSubHeading";
import ResultsText from "../results-text/ResultsText";
import "./IntroWinCustomers.scss";
import classNames from "classnames";
import { ThemeColours } from "../../../../types/theme";

interface SectionProps {
  className: string;
  visible?: boolean;
  icon: IconType;
  heading: string;
  description: string;
  additionalDescription?: string;
  iconColour?: ThemeColours;
  iconStrokeColour?: ThemeColours;
}

const Section: React.FC<SectionProps> = ({
  className,
  visible = true,
  icon,
  heading,
  description,
  additionalDescription,
  iconColour,
  iconStrokeColour,
}) => {
  return (
    <InlineGroup
      className={classNames("content-item", className, {
        visible: visible,
      })}
      verticalCenter
      fullHeight
      block
    >
      <Icon
        colour={iconColour}
        stroke={iconStrokeColour}
        type={icon}
        dynamicSize={{
          min: "60px",
          preferred: "5vw",
          max: "300px",
        }}
      />

      <VerticalGroup wide verticalCenter>
        <ResultsText left bold>
          {heading}
        </ResultsText>
        <ul>
          <li>
            <ResultsText left>{description}</ResultsText>
          </li>
          {additionalDescription && (
            <li>
              <ResultsText left>{additionalDescription}</ResultsText>
            </li>
          )}
        </ul>
      </VerticalGroup>
    </InlineGroup>
  );
};

export const winCustomersMaxClicks = 5;

interface ResultsContext {
  clicks: number;
  noAnimations: boolean;
  addClick: () => void;
  goNextPage: () => void;
}

const IntroWinCustomers: React.FC = () => {
  const context = useResultsContext() as ResultsContext;

  const onNext = useCallback(() => {
    if (context.clicks < winCustomersMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showPrice = context.noAnimations || context.clicks >= 1;
  const showTurnover = context.noAnimations || context.clicks >= 2;
  const showPriceLever = context.noAnimations || context.clicks >= 3;
  const showInvestment = context.noAnimations || context.clicks >= 4;
  const showMarketGrowth = context.noAnimations || context.clicks >= 5;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight sharp>
        <VerticalGroup
          verticalCenter
          wide
          center
          full
          className="intro-win-customers"
        >
          <EditableReportingHeading defaultHeading="How to win customers and keep them" />
          <ReportingSubHeading subHeading="Pricing is the easiest way to win customers, but its also the most expensive." />
          <div className="content-wrapper">
            <div className="puzzle-man-section">
              <InlineGroup block fullHeight center verticalCenter>
                <Icon
                  type="puzzleMan"
                  dynamicSize={{
                    min: "130px",
                    preferred: "26vw",
                    max: "1000px",
                  }}
                />
              </InlineGroup>
            </div>

            <InlineGroup verticalTop fullHeight block>
              <VerticalGroup
                full
                spread
                wide
                style={{ height: "95%" }}
                className="content-section"
              >
                <Section
                  className="price-section"
                  visible={showPrice}
                  icon="dollar"
                  heading="Price"
                  description="Larger price differences vs. peer banks will attract customers."
                  iconColour="blue"
                  iconStrokeColour="blue"
                />

                <Section
                  className="turnover-section"
                  visible={showTurnover}
                  icon="turnOver"
                  heading="Customer Turnover"
                  description="Each product has different customer lives. Happier customers will stay longer."
                />

                <Section
                  className="levers-section"
                  visible={showPriceLever}
                  icon="lightBulb"
                  heading="Non-Price Levers"
                  description="Customers are influenced by their advisors, bank service levels, rewards, accessibility, etc"
                  additionalDescription="These factors are more subtle, cost effective, and often enduring."
                />

                <Section
                  className="investment-section"
                  visible={showInvestment}
                  icon="investment"
                  heading="Investment"
                  description="Access, features & technology which makes customer lives easier (project spend and distribution network)."
                />

                <Section
                  className="market-growth-section"
                  visible={showMarketGrowth}
                  icon="marketGrowth"
                  heading="Market Growth"
                  description="Changing market conditions determine the number of customers entering/exiting the market each year."
                />
              </VerticalGroup>
            </InlineGroup>
          </div>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroWinCustomers;
