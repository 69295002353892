import React, { useCallback, useEffect, useState } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon, { IconType } from "../../../atoms/icon/Icon";
import ResultsText from "../results-text/ResultsText";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Card from "../../../atoms/card/Card";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ReportingSubHeading from "../components/ReportingSubHeading";
import Table, { Td, Th, Tr } from "../../../atoms/table/Table";
import "./IntroPricingStrategy.scss";
import {
  buildStyles,
  CircularProgressbarWithChildren as Progress,
} from "react-circular-progressbar";
import { ThemeColours } from "../../../../types/theme";
import Theme from "../../../../styles/_theme.module.scss";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

interface TableHeaderColumnProps {
  iconType?: IconType;
  headerText: string;
  visible?: boolean;
  className?: string;
}

interface ProgressDisplayProps {
  value: number;
  maxValue: number;
  innerText: string;
  outcomeValue?: string | null;
  progressColor?: ThemeColours;
  noBorder?: boolean;
  visible?: boolean;
  className?: string;
}

interface ProgressDisplayProps {
  value: number;
  maxValue: number;
  innerText: string;
  outcomeValue?: string | null;
  progressColor?: ThemeColours;
  noBorder?: boolean;
  visible?: boolean;
  className?: string;
  innerTextColour?: ThemeColours;
}

const ProgressDisplay: React.FC<ProgressDisplayProps> = ({
  value,
  progressColor = "blue",
  innerText,
  innerTextColour,
  outcomeValue,
  maxValue,
  noBorder = false,
  visible,
  className,
}) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const isNegative = value < 0;

  useEffect(() => {
    if (visible) {
      setAnimatedValue(0);

      const duration = 500;
      const startTime = performance.now();

      const animate = (currentTime: number) => {
        const elapsed = currentTime - startTime;

        if (elapsed < duration) {
          const progress = elapsed / duration;
          setAnimatedValue(value * progress);
          requestAnimationFrame(animate);
        } else {
          setAnimatedValue(value);
        }
      };

      requestAnimationFrame(animate);
    }
  }, [visible, value]);

  const absoluteValue = Math.abs(animatedValue);
  const percentage = (absoluteValue / maxValue) * 100;

  return (
    <VerticalGroup
      className={classNames("pricing-progress", className, {
        visible: visible,
      })}
      center
      spaceBetweenElements={1}
    >
      <div className="time-progress-container">
        <Progress
          value={percentage}
          strokeWidth={8}
          counterClockwise={isNegative}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: Theme[progressColor],
            pathTransitionDuration: 0.1,
          })}
        >
          <InlineGroup className="progress-inner-text" center>
            <ResultsText colour={innerTextColour} bold>
              {innerText}
            </ResultsText>
          </InlineGroup>
        </Progress>
      </div>
      {outcomeValue ? (
        <InlineGroup
          verticalCenter
          border={
            !noBorder
              ? {
                  style: "solid",
                  color: "darkgrey",
                  width: "0.5px",
                  rounded: "0.2rem",
                }
              : undefined
          }
          bgColour="grey1"
        >
          <InlineGroup className="percentage-display">
            <ResultsText bold size="sm">
              {outcomeValue}
            </ResultsText>
          </InlineGroup>
        </InlineGroup>
      ) : (
        <InlineGroup verticalCenter>
          <InlineGroup className="percentage-display">
            <ResultsText bold size="sm">
              {"\u200b"}
            </ResultsText>
          </InlineGroup>
        </InlineGroup>
      )}
    </VerticalGroup>
  );
};

const TableHeaderColumn: React.FC<TableHeaderColumnProps> = ({
  iconType = "currentRate",
  headerText,
  visible,
  className,
}) => {
  return (
    <VerticalGroup
      className={classNames("header-item", className, {
        visible: visible,
      })}
      center
    >
      <InlineGroup>
        <div className="icon-border-container">
          <Icon
            dynamicSize={{ min: "20px", preferred: "2vw", max: "900px" }}
            type={iconType}
          />
        </div>
      </InlineGroup>
      <InlineGroup
        width="70%"
        verticalCenter
        border={{
          style: "solid",
          color: "darkgrey",
          width: "0.5px",
          rounded: "20px",
        }}
        bgColour="primaryDark"
      >
        <InlineGroup center block className="pricing-heading-tab">
          <ResultsText center size="sm" colour="white">
            {headerText}
          </ResultsText>
        </InlineGroup>
      </InlineGroup>
    </VerticalGroup>
  );
};
export const pricingStrategyMaxClicks = 16;
const IntroPricingStrategy = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < pricingStrategyMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showHomeLoansCard = context.noAnimations || context.clicks >= 1;
  const showCurrentPrice = context.noAnimations || context.clicks >= 2;
  const showNewRate = context.noAnimations || context.clicks >= 3;
  const showGrowth = context.noAnimations || context.clicks >= 4;
  const showIncome = context.noAnimations || context.clicks >= 5;
  const showImpact = context.noAnimations || context.clicks >= 6;
  const showBottomArrow = context.noAnimations || context.clicks >= 7;
  const showNewRateBottom = context.noAnimations || context.clicks >= 8;
  const showGrowthBottom = context.noAnimations || context.clicks >= 9;
  const showIncomeBottom = context.noAnimations || context.clicks >= 10;
  const showImpactBottom = context.noAnimations || context.clicks >= 11;
  const showTopArrow = context.noAnimations || context.clicks >= 12;
  const showNewRateTop = context.noAnimations || context.clicks >= 13;
  const showGrowthTop = context.noAnimations || context.clicks >= 14;
  const showIncomeTop = context.noAnimations || context.clicks >= 15;
  const showImpactTop = context.noAnimations || context.clicks >= 16;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight>
        <VerticalGroup full center wide>
          <EditableReportingHeading defaultHeading="Managing Volume and Margin" />
          <ReportingSubHeading subHeading="Short-Term Pricing Strategies" />
          <InlineGroup
            className="pricing-strategy-table"
            fullHeight
            block
            center
          >
            <Table className=" no-stripe">
              <Tr>
                <Th className="home-loan-card-column"></Th>
                <Th className="pricing-column" center>
                  <TableHeaderColumn
                    visible={showCurrentPrice}
                    iconType="currentRate"
                    headerText="Current Rate"
                  />
                </Th>
                <Th className="pricing-column" center>
                  <TableHeaderColumn
                    iconType="newRate"
                    headerText="New Rate"
                    visible={showNewRate}
                  />
                </Th>
                <Th className="pricing-column" center>
                  <TableHeaderColumn
                    iconType="growth"
                    headerText="Growth"
                    visible={showGrowth}
                  />
                </Th>
                <Th className="pricing-column" center>
                  <TableHeaderColumn
                    iconType="income"
                    headerText="Income"
                    visible={showIncome}
                  />
                </Th>
                <Th center>
                  <TableHeaderColumn
                    iconType="impact"
                    headerText="Customer Impact"
                    visible={showImpact}
                  />
                </Th>
              </Tr>

              <tbody>
                <Tr>
                  <Td rowSpan={4} width={4}></Td>
                  <Td rowSpan={4} left className="home-loan-column">
                    <InlineGroup center verticalCenter>
                      <VerticalGroup
                        width="55%"
                        center
                        className={classNames("home-loan-card", {
                          show: showHomeLoansCard,
                        })}
                      >
                        <div className="pricing-card-text-container">
                          <ResultsText bold center className="pricing-text">
                            HOME LOANS
                          </ResultsText>
                        </div>
                        <Icon
                          dynamicSize={{
                            min: "40px",
                            preferred: "6vw",
                            max: "900px",
                          }}
                          type="homeLoanCard"
                        />
                      </VerticalGroup>
                      <VerticalGroup>
                        <div className="pricing-text-container">
                          <ResultsText
                            className={classNames("increase-text", {
                              show: showTopArrow,
                            })}
                            bold
                            size="sm"
                          >
                            Increase
                          </ResultsText>
                        </div>
                        <div
                          className={classNames("pricing-top-arrow-container", {
                            show: showTopArrow,
                          })}
                        >
                          <Icon
                            dynamicSize={{
                              min: "50px",
                              preferred: "13vw",
                              max: "900px",
                            }}
                            type="topGreenArrow"
                          />
                        </div>

                        <InlineGroup
                          className={classNames("current-price p-2 mb-6", {
                            show: showCurrentPrice,
                          })}
                          border={{
                            style: "solid",
                            color: "darkgrey",
                            width: "0.8px",
                            rounded: "0.5rem",
                          }}
                          bgColour="grey1"
                        >
                          <ResultsText bold size="lg">
                            4.85%
                          </ResultsText>
                        </InlineGroup>

                        <div
                          className={classNames(
                            "pricing-bottom-arrow-container",
                            { show: showBottomArrow },
                          )}
                        >
                          <Icon
                            dynamicSize={{
                              min: "40px",
                              preferred: "13vw",
                              max: "900px",
                            }}
                            type="bottomYellowArrow"
                          />
                        </div>
                        <div
                          className={classNames(
                            "pricing-bottom-text-container",
                            { show: showBottomArrow },
                          )}
                        >
                          <ResultsText bold size="sm">
                            Decrease
                          </ResultsText>
                        </div>
                      </VerticalGroup>
                      <VerticalGroup>
                        <div
                          className={classNames(
                            "pricing-right-arrow-container",
                            { show: showNewRate },
                          )}
                        >
                          <Icon
                            dynamicSize={{
                              min: "20px",
                              preferred: "8vw",
                              max: "900px",
                            }}
                            type="rightBlueArrow"
                          />
                        </div>
                        <div
                          className={classNames(
                            "pricing-right-text-container",
                            { show: showNewRate },
                          )}
                        >
                          <ResultsText bold size="sm">
                            No Change
                          </ResultsText>
                        </div>
                      </VerticalGroup>
                    </InlineGroup>
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={20}
                      maxValue={100}
                      innerText="+30 bps"
                      outcomeValue="5.15%"
                      progressColor="lightgreen"
                      visible={showNewRateTop}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={10}
                      maxValue={100}
                      innerText="+5%"
                      outcomeValue="Below Market"
                      noBorder
                      visible={showGrowthTop}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={45}
                      maxValue={100}
                      innerText="14%"
                      outcomeValue={null}
                      visible={showIncomeTop}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ResultsText
                      className={classNames("high-impact mb-6", {
                        show: showImpactTop,
                      })}
                      size="3xl"
                    >
                      😊
                    </ResultsText>
                  </Td>
                </Tr>
                <Tr>
                  <Td center>
                    <ProgressDisplay
                      value={0}
                      maxValue={100}
                      innerText="No change"
                      outcomeValue="4.85%"
                      progressColor="mediumgrey"
                      visible={showNewRate}
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={25}
                      maxValue={100}
                      innerText="+10%"
                      outcomeValue="market Growth"
                      noBorder
                      visible={showGrowth}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={25}
                      maxValue={100}
                      innerText="+10%"
                      outcomeValue={null}
                      visible={showIncome}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ResultsText
                      className={classNames("normal-impact mb-6", {
                        show: showImpact,
                      })}
                      size="3xl"
                    >
                      😐
                    </ResultsText>
                  </Td>
                </Tr>
                <Tr>
                  <Td center>
                    <ProgressDisplay
                      value={-20}
                      maxValue={100}
                      innerText="-30 bps"
                      outcomeValue="4.55%"
                      progressColor="yellow"
                      visible={showNewRateBottom}
                      innerTextColour="danger"
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={50}
                      maxValue={100}
                      innerText="+15%"
                      outcomeValue="Above Market"
                      noBorder
                      visible={showGrowthBottom}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ProgressDisplay
                      value={10}
                      maxValue={100}
                      innerText="+6%"
                      outcomeValue={null}
                      visible={showIncomeBottom}
                      innerTextColour="green"
                    />
                  </Td>
                  <Td center>
                    <ResultsText
                      className={classNames("sad-impact mb-6", {
                        show: showImpactBottom,
                      })}
                      size="3xl"
                    >
                      🙁
                    </ResultsText>
                  </Td>
                </Tr>
              </tbody>
            </Table>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroPricingStrategy;
