import classNames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";
import Badge from "../../../atoms/badge/Badge";
import Clickable from "../../../atoms/clickable/Clickable";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import RoundResultsPage from "../../results/RoundResultsPage";

import "./ReportingTemplate.scss";
import ContentStretcher from "./ContentStretcher";
import ReportingPagePreviewTile, {
  RENDERED_DIMENSIONS,
} from "./ReportingPagePreviewTile";
import { LayoutResponse } from "../types";

interface Props {
  round: number;
  data: ModelAPI.Reporting.ReportingResults;
  mode: "fullscreen" | "thumbnails";
  setMode: (val: "fullscreen" | "thumbnails") => void;
  previewHeight: number;
  previewWidth: number;
  availableHeight: number;
  availableWidth: number;
  resultsView?: boolean;
  noAnimations?: boolean;
  noAudio?: boolean;
  noVideo?: boolean;
  hidePagination?: boolean;
}

const ReportingTemplatePreviewContent: React.FC<Props> = ({
  data,
  round,
  mode,
  setMode,
  previewHeight,
  previewWidth,
  availableHeight,
  availableWidth,
  resultsView,
  noAnimations,
  noAudio,
  noVideo,
  hidePagination,
}) => {
  const [selectedLayout, setSelectedLayout] = useState<LayoutResponse | null>(
    null,
  );

  const slides = useMemo(() => {
    if (data) {
      return data.layouts.filter((layout) => layout.enabled);
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (mode === "fullscreen" && selectedLayout == null) {
      setSelectedLayout(slides[0]);
    }
  }, [mode, selectedLayout, slides, round]);

  return (
    <>
      <div
        className={classNames({
          "reporting-template-select-container": !resultsView,
        })}
      >
        {mode === "thumbnails" &&
          slides.map((layout, layoutIndex) => (
            <Clickable
              key={layoutIndex}
              onClick={() => {
                setSelectedLayout(layout);
                setMode("fullscreen");
              }}
            >
              <ReportingPagePreviewTile
                key={layout.id + layout.overrideDisabled}
                data={{
                  ...data,
                  layouts: [layout],
                }}
                roundNumber={round}
                layout={layout}
                showHeader
                showTeamPositions
                overlay={
                  <Badge
                    value={layoutIndex + 1}
                    colour="green"
                    size={24}
                    textSize="5xl"
                    floating
                  />
                }
              />
            </Clickable>
          ))}
        {mode === "fullscreen" && availableHeight > 0 && availableWidth > 0 && (
          <VerticalGroup
            center
            style={{ flex: 1, height: availableHeight, width: availableWidth }}
          >
            {selectedLayout != null &&
              previewHeight > 0 &&
              previewWidth > 0 && (
                <ContentStretcher
                  style={{
                    background: "#e1e1e1",
                    width: previewWidth,
                    height: previewHeight,
                    minHeight: previewHeight,
                  }}
                  contentWidth={RENDERED_DIMENSIONS.width}
                  contentHeight={RENDERED_DIMENSIONS.height}
                >
                  <div
                    style={{
                      width: RENDERED_DIMENSIONS.width,
                      height: RENDERED_DIMENSIONS.height,
                      minHeight: RENDERED_DIMENSIONS.height,
                      position: "absolute",
                    }}
                  >
                    <RoundResultsPage
                      data={{
                        ...data,
                        layouts: slides,
                      }}
                      roundNumber={round}
                      onlyThesePages={[]}
                      startingLayout={selectedLayout.type}
                      noAnimations={noAnimations}
                      noAudio={noAudio}
                      noVideo={noVideo}
                      hidePagination={hidePagination}
                    />
                  </div>
                </ContentStretcher>
              )}
          </VerticalGroup>
        )}
      </div>
    </>
  );
};

export default memo(ReportingTemplatePreviewContent);
