import React from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Image from "../../../atoms/image/Image";
import confucius from "../../../../assets/results/confucius.png";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import useIsMobileOrTablet from "../../../../hooks/useIsMobileOrTablet";

const IntroConfuciusSlide = () => {
  const IsMobileOrTablet = useIsMobileOrTablet();
  return (
    <RoundResultsContainer>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Banking Simulation" />
          <InlineGroup center verticalCenter fullHeight block>
            <Image
              className={
                IsMobileOrTablet ? "width-50-percent" : "width-40-percent"
              }
              contain
              alt={""}
              src={confucius}
            />
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroConfuciusSlide;
