import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsInfoCard from "../components/ResultsInfoCard";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import "./IntroSimulationSetup.scss";
import ResultsText from "../results-text/ResultsText";
import { useResultsContext } from "../context/context";
import classNames from "classnames";

export const simulationSetupMaxClicks = 4;

const IntroSimulationSetup = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < simulationSetupMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);
  const showSetUpText = context.noAnimations || context.clicks >= 1;
  const showTeamCard = context.noAnimations || context.clicks >= 2;
  const showBankNameCard = context.noAnimations || context.clicks >= 3;
  const showStrategyCard = context.noAnimations || context.clicks >= 4;
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="simulation-setup-slide" wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Simulation Set Up" />
          <InlineGroup className="mt-8" block>
            <ResultsText
              className={classNames("setup-text", {
                show: showSetUpText,
              })}
              size="lg"
            >
              10 minutes for Round 1 Set Up:
            </ResultsText>
          </InlineGroup>
          <InlineGroup fullHeight verticalCenter>
            <InlineGroup
              className={classNames("set-up-team-card", {
                show: showTeamCard,
              })}
            >
              <ResultsInfoCard
                themeColour="primaryDark"
                headerText="Team Member Intros"
                BadgeText="2m"
                infoPoints={["Who you are", "Your role"]}
              />
            </InlineGroup>
            <InlineGroup
              className={classNames("setup-bank-name-card", {
                show: showBankNameCard,
              })}
            >
              <ResultsInfoCard
                themeColour="blue"
                headerText="Bank Name"
                BadgeText="1m"
                infoPoints={[
                  "Up to 8 characters",
                  "Don't need to add Bank at the end",
                  "Will be shown to everyone.",
                ]}
              />
            </InlineGroup>
            <InlineGroup
              className={classNames("setup-strategy-card", {
                show: showStrategyCard,
              })}
            >
              <ResultsInfoCard
                themeColour="yellow"
                headerText="Devise Team Strategy"
                BadgeText="7m"
                infoPoints={[
                  "Bullet points is fine",
                  "~3 guiding principles for running your banks.",
                ]}
              />
            </InlineGroup>
          </InlineGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroSimulationSetup;
