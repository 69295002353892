import React from "react";
import Card from "../../../atoms/card/Card";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Image from "../../../atoms/image/Image";
import ResultsText from "../results-text/ResultsText";
import ReportingSubHeading from "../components/ReportingSubHeading";

interface VirtualMeetingIntroProps {
  defaultHeading: string;
  imageSrc: string;
  steps: string[];
}

const VirtualMeetingIntro: React.FC<VirtualMeetingIntroProps> = ({
  defaultHeading,
  imageSrc,
  steps,
}) => {
  return (
    <Card wide fullHeight>
      <VerticalGroup wide center full spread>
        <EditableReportingHeading defaultHeading={defaultHeading} />
        <ReportingSubHeading subHeading="Important items for an optimal learning experience" />

        <VerticalGroup
          full
          wide
          center
          verticalCenter
          spaceBetweenElements={10}
        >
          <Image
            alt="Meeting Logistics"
            src={imageSrc}
            dynamicSize={{
              min: "600px",
              preferred: "90%",
              max: "3500px",
            }}
            contain
          />

          <VerticalGroup spread width="90%" half wide>
            {steps.map((step: string, index: number) => (
              <VerticalGroup key={index}>
                <ResultsText left bold colour="primaryDark" size="lg">
                  {`${index + 1}.) `} {step}
                </ResultsText>
              </VerticalGroup>
            ))}
          </VerticalGroup>
        </VerticalGroup>
      </VerticalGroup>
    </Card>
  );
};

export default VirtualMeetingIntro;
