import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import "./IntroGrowthFunding.scss";

export const IntroGrowthFundingMaxClicks = 2;

const IntroGrowthFunding = () => {
  const context = useResultsContext();
  const onNext = useCallback(() => {
    if (context.clicks < IntroGrowthFundingMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showTopSection = context.noAnimations || context.clicks >= 1;
  const showBottomSection = context.noAnimations || context.clicks >= 2;
  return (
    <RoundResultsContainer onNext={onNext}>
      <Card className="growth-funding" wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Funding Your Growth" />
          <VerticalGroup
            width="85%"
            wide
            spaceBetweenElements={10}
            full
            verticalCenter
          >
            <InlineGroup
              className={classNames("growth-funding-top-section", {
                show: showTopSection,
              })}
              spaceBetweenElements={8}
              verticalCenter
              block
            >
              <Icon
                colour="yellow"
                type="growthFunding"
                dynamicSize={{ min: "50px", preferred: "8vw", max: "900px" }}
              />
              <VerticalGroup>
                <ResultsText size="xl" bold>
                  All Growth Needs To Be Funded
                </ResultsText>
                <ResultsText size="xl">
                  Via deposits or wholesale funding.
                </ResultsText>
              </VerticalGroup>
            </InlineGroup>
            <InlineGroup
              className={classNames("growth-funding-bottom-section", {
                show: showBottomSection,
              })}
              spaceBetweenElements={8}
              verticalCenter
              block
            >
              <Icon
                stroke="yellow"
                colour="yellow"
                type="dollar"
                dynamicSize={{ min: "50px", preferred: "8vw", max: "900px" }}
              />
              <VerticalGroup>
                <ResultsText size="xl" bold>
                  Ensure You Hold A Cash (Liquidity) Buffer
                </ResultsText>
                <ResultsText size="xl">
                  This is to ensure safety of your bank
                </ResultsText>
              </VerticalGroup>
            </InlineGroup>
            <InlineGroup block center>
              <ResultsText
                className={classNames("growth-funding-bottom-text", {
                  show: showBottomSection,
                })}
                center
                italic
                bold
                size="xl"
              >
                If you do not hold sufficient cash...
              </ResultsText>
            </InlineGroup>
          </VerticalGroup>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroGrowthFunding;
