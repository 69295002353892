import React, { useState } from "react";
import { useElementSize } from "usehooks-ts";
import Banner from "../../../../../atoms/banner/Banner";
import InlineGroup from "../../../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../../../atoms/loadingspinner/LoadingSpinner";
import Text from "../../../../../atoms/text/Text";
import RoundDropdown from "../../../../../organisms/round-dropdown/RoundDropdown";
import ReportingTemplatePreviewContent from "../../../../reporting-templates/components/ReportingTemplatePreviewContent";
import ReportingTemplatePreviewContentFetcher from "../../../../reporting-templates/components/ReportingTemplatePreviewContentFetcher";
import ReportingTemplatePreviewHeader from "../../../../reporting-templates/components/ReportingTemplatePreviewHeader";
import ReportingTemplatePreviewModal from "../../../../reporting-templates/components/ReportingTemplatePreviewModal";

interface Props {
  reportingTemplateId?: string;
  introTemplateId?: string;
  simulationId: string;
  brandingId: string;
  isOpen: boolean;
  onClose: () => void;
  maxRounds?: number;
}

export const EventReportingTemplatePreviewModal: React.FC<Props> = ({
  reportingTemplateId,
  introTemplateId,
  simulationId,
  brandingId,
  isOpen,
  onClose,
  maxRounds,
}) => {
  if (!reportingTemplateId && !introTemplateId) {
    throw new Error(
      "Either reportingTemplateId or introTemplateId is required",
    );
  }
  const [mode, setMode] = useState<"fullscreen" | "thumbnails">("thumbnails");
  const [round, setRound] = useState(introTemplateId ? 0 : 1);
  const [headingRef, { height: headingHeight }] = useElementSize();

  return (
    <>
      <ReportingTemplatePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        headingHeight={headingHeight}
      >
        {({ previewHeight, previewWidth, availableHeight, availableWidth }) => (
          <>
            <ReportingTemplatePreviewHeader
              mode={mode}
              setMode={setMode}
              ref={headingRef}
            >
              {!introTemplateId && (
                <InlineGroup spaceBetweenElements={2} verticalCenter>
                  <Text>Round:</Text>
                  <RoundDropdown
                    value={round}
                    onChange={(selected) => setRound(parseInt(selected.value))}
                    min={1}
                    max={maxRounds}
                    tiny
                  />
                </InlineGroup>
              )}
            </ReportingTemplatePreviewHeader>
            <ReportingTemplatePreviewContentFetcher
              introTemplateId={introTemplateId}
              reportingTemplateId={reportingTemplateId}
              simulationId={simulationId}
              brandingId={brandingId}
              round={round}
            >
              {({ inProgress, error, data }) => (
                <>
                  {error && (
                    <Banner
                      type="error"
                      active={!!error}
                      message={error?.message}
                    />
                  )}
                  {inProgress && <LoadingSpinner />}
                  {data && (
                    <ReportingTemplatePreviewContent
                      round={round}
                      data={data}
                      mode={mode}
                      setMode={setMode}
                      previewHeight={previewHeight - 30}
                      previewWidth={previewWidth + 225}
                      availableHeight={availableHeight - 70}
                      availableWidth={availableWidth - 10}
                    />
                  )}
                </>
              )}
            </ReportingTemplatePreviewContentFetcher>
          </>
        )}
      </ReportingTemplatePreviewModal>
    </>
  );
};
