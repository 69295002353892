import React, { useCallback } from "react";
import Card from "../../../atoms/card/Card";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import "./IntroAccessingSimulation.scss";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsText from "../results-text/ResultsText";
import { useResultsContext } from "../context/context";
import classNames from "classnames";
import "./IntroAccessingSimulation.scss";

export const accessingSimulationMaxClicks = 2;

const IntroAccessingSimulation = () => {
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < accessingSimulationMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const showSyncingSection = context.noAnimations || context.clicks >= 1;
  const showPricingSection = context.noAnimations || context.clicks >= 2;

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card wide fullHeight>
        <VerticalGroup wide center full spread>
          <EditableReportingHeading defaultHeading="Accessing The Simulation" />
          <div className="timeline-container-sim">
            <VerticalGroup className="timeline-items-sim">
              <InlineGroup className="timeline-item-sim">
                <div className="timeline-icon-sim">
                  <Icon
                    type="ceo"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <div className="timeline-content-sim">
                  <ResultsText left bold size="lg">
                    We are about to send you an email
                  </ResultsText>
                  <ul className="ceo-info-first-group-sim">
                    <InlineGroup spaceBetweenElements={4}>
                      <ResultsText>-</ResultsText>
                      <ResultsText left>
                        From Excelerate Consulting.
                      </ResultsText>
                    </InlineGroup>
                    <InlineGroup spaceBetweenElements={4}>
                      <ResultsText>-</ResultsText>
                      <ResultsText left>
                        This will contain your login link to the simulation..
                      </ResultsText>
                    </InlineGroup>
                    <InlineGroup spaceBetweenElements={4}>
                      <ResultsText>-</ResultsText>
                      <ResultsText left>
                        Check your junk / spam if you can not see it
                      </ResultsText>
                    </InlineGroup>
                  </ul>
                </div>
              </InlineGroup>
              <div
                className={classNames("timeline-item-syncing-sim", {
                  show: showSyncingSection,
                })}
              >
                <div className="timeline-icon-sim">
                  <Icon
                    type="syncing"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <VerticalGroup verticalCenter className="timeline-content-sim">
                  <ResultsText left bold size="lg">
                    This will contain your login details & team #
                  </ResultsText>
                </VerticalGroup>
              </div>
              <div
                className={classNames("timeline-item-pricing-sim", {
                  show: showPricingSection,
                })}
              >
                <div className="timeline-icon-sim">
                  <Icon
                    type="growthFunding"
                    dynamicSize={{
                      min: "50px",
                      preferred: "8vw",
                      max: "900px",
                    }}
                  />
                </div>
                <VerticalGroup verticalCenter className="timeline-content-sim">
                  <ResultsText left size="lg" bold>
                    Otherwise, we will share usernames and passwords in a
                    moment.
                  </ResultsText>
                </VerticalGroup>
              </div>
              <div className="timeline-line-sim"></div>
            </VerticalGroup>
          </div>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default IntroAccessingSimulation;
