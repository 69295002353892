import React, { ReactNode } from "react";
import Modal from "../../atoms/modal/Modal";

import "./StandardModal.scss";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Button from "../../atoms/button/Button";
import ModalBody from "../../molecules/modal-body/ModalBody";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string | ReactNode;
  className?: string;
}

function WarningModal({
  isOpen,
  onClose,
  title,
  description,
  className,
}: Props) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalBody
        type="warning"
        title={title ?? "Warning"}
        description={description}
        icon="error"
        className={className}
      >
        <InlineGroup className="mt-4" spread reverse>
          <Button data-test="modal-confimation-confirm" onClick={onClose} large>
            Close
          </Button>
        </InlineGroup>
      </ModalBody>
    </Modal>
  );
}

export default WarningModal;
